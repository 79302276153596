import styled from 'styled-components'
import WidgetBox from "../../../../components/WidgetBox"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`

export const ProfileWidgetBox = styled(WidgetBox)`
  flex: 1;
`

export const GridHome = styled.div.attrs(() => ({
  className: "grid grid-3-6-3"
}))`
    &&& {

      grid-template-areas:"pinfo pfabt intrts"
                          "wked wked wked";
      grid-template-rows: repeat(2, auto);
      
      .personal-info.grid-column {            
      grid-area: pinfo;
      min-width: 20rem;
      height: 100%;
      }
      div.profile-about.grid-column {
      grid-area: pfabt;
      max-width: 92%;
      justify-self: end;
      height: 100%;
      }
      div.interests.grid-column {
          grid-area: intrts;
          height: 100%;
      }
      div.work-education.grid-column {
          grid-area: wked;
          height: 100%;
      }
      @media(max-width: 85.35rem) {
          grid-template-areas: "pfabt pinfo"
                               "intrts wked";
          grid-template-columns: 50% 49%;
          .personal-info.grid-column {            
            min-width: unset;
            }
            div.profile-about.grid-column {
            max-width: unset;
            }
      }
      @media(max-width: 40rem) {
          grid-template-rows: repeat(4, auto);
          grid-template-columns: 100%;
          grid-template-areas: "pfabt"
                               "pinfo"
                               "intrts"
                               "wked";
      }
    }
`