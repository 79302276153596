import { useCallback } from 'react';
import api from '../services/api';

export function useWorksheetDate () {
  const load = useCallback(async (stepId) => {
    const response = await api.get('/data', { params: { stepId: stepId || 1 }})
    const newDate = new Date(response.data.data)
    const type = response.data.type
    return `${type} etapa ${stepId} - Atualizado em ${newDate.getDate()}/${newDate.getMonth() + 1}`
  }, [])

  return load
}