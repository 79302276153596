import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import PerfectScrollbar from 'react-perfect-scrollbar'
import PageLoaderComponent from '../../../components/PageLoader';
import { useAuth } from '../../../contexts/AuthContext';

// Services
import api from '../../../services/api';
import { useModal } from '../../../contexts/ModalContext';
import { useLockedModal } from '../../../contexts/LockedModalContext';
import { useCampaign } from '../../../contexts/CampaignContext';
import { UserUpdateModal } from '../../../components/UserUpdateForm';
import { UserUpdateHoldingModal } from '../../../components/UserUpdateHoldingForm';
import { UserUpdatePasswordForm } from '../../../components/UserUpdatePasswordForm';
import logo from '../../../assets/img/logo-vitamine-com-tang.png'

// Styles
import * as S from "./style"

export default function Activation () {
    const { token } = useParams();
    const navigate = useNavigate();
    const [term, setTerm] = useState({});
    const { campaignData } = useCampaign()
    const { userCan } = useAuth()
    const { openModal, closeModal } = useModal();
    const { openModal: openLockedModal, closeModal: closeLocked } = useLockedModal();

    const handleConfirmTerm = useCallback(async () => {
        closeLocked();
        try {
            const response = await api.post(`/activate/${token.replace("token=", '')}`)
            toast.success(response.data.message, {
                theme: "colored"
            });

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));

            navigate('/home');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data?.message, {
                theme: "colored"
            });
        }
    }, [closeLocked, navigate, token]);

    const handleRejectTerm = useCallback(async () => {
        try {
            const response = await api.get(`/activate/${token.replace("token=", '')}/reject`)
            toast.success(response.data.message, {
                theme: "colored"
            });
            closeModal();
            navigate("/");
        } catch (err) {
            toast.error(err.response.data.message, {
                theme: "colored"
            });
        }
    }, [closeModal, navigate, token])

    const openUpdateUserModal = useCallback(async () => {
        closeModal(() => {
            openLockedModal({
                header: 'Atualize seus dados',
                body: <UserUpdateModal onSave={handleConfirmTerm} />
            })
        })
    }, [closeModal, openLockedModal, handleConfirmTerm]);

    const openUpdateUserLockedModal = useCallback(async () => {
        closeLocked(() => {
            openLockedModal({
                header: 'Atualize seus dados',
                body: <UserUpdateModal onSave={handleConfirmTerm} />
            })
        })
    }, [closeLocked, openLockedModal, handleConfirmTerm]);

    const openUpdateHoldingModal = useCallback(() => {
        if (!userCan("holdings:update")) {
            openUpdateUserModal()
            return
        }
        closeModal(() => {
            openLockedModal({
                header: 'Atualize os dados da sua rede',
                body: <UserUpdateHoldingModal onSave={openUpdateUserLockedModal} />
            })
        })
    }, [userCan, closeModal, openUpdateUserModal, openLockedModal, openUpdateUserLockedModal]);

    const openUpdatePasswordModal = useCallback(() => {
        closeModal(() => {
            openModal({
                header: 'Atualize sua senha',
                body: <UserUpdatePasswordForm onSave={openUpdateHoldingModal} />
            })
        })
    }, [closeModal, openModal, openUpdateHoldingModal]);

    const openAcceptModal = useCallback (() => {
        openModal({
            header: 'Você tem certeza?',
            body: <>
                <p>Você tem certeza que deseja participar da campanha {campaignData.title}?</p>
                <S.ButtonContainer>
                    <button className="button secondary" onClick={() => closeModal()}>Voltar</button>
                    <button className="button secondary" onClick={openUpdatePasswordModal}>Aceitar</button>
                </S.ButtonContainer>
            </>
        });
    }, [openModal, campaignData, openUpdatePasswordModal, closeModal]);

    const openRejectModal = useCallback (() => {
        openModal({
            header: 'Você tem certeza?',
            body: <>
                <p>Você tem certeza que NÃO deseja participar da campanha {campaignData.title}?</p>
                <S.ButtonContainer>
                    <button className="button secondary" onClick={handleRejectTerm}>Recusar</button>
                    <button className="button secondary" onClick={() => closeModal()}>Voltar</button>
                </S.ButtonContainer>
            </>
        });
    }, [openModal, campaignData, handleRejectTerm, closeModal]);

    useEffect(() => {
        const Data = async () => {
            try {
                const { data } = await api.get(`term/${token.replace("token=", '')}`)
                setTerm(data)

            } catch (err) {
                toast.error(err.response.data.message, {
                    theme: "colored"
                });
                // navigate("/");
            }
        };
        Data();
    }, [token])

    if (!term) <PageLoaderComponent />

    return <S.Container className="landing">
                    <img src={logo} alt="Logo tang" style={{position: 'absolute', maxWidth: '200px', left: '0px', top: '0px', zIndex: '999'}} />
            <PerfectScrollbar >
                <S.Body>
                    <div className="text-center mb-5">
                        <h1>Termos e Condições</h1>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: term.content}}></p>

                    <div className="form-row" style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        <div className="form-item" style={{ width: '48%' }}>
                            <button className="button medium secondary mt-5" type="text" onClick={openRejectModal}>
                                Recuso os termos
                            </button>
                        </div>

                        <div className="form-item" style={{ width: '48%' }}>
                            <button className="button medium secondary mt-5" type="text" onClick={openAcceptModal}>
                                Aceito os termos
                            </button>
                        </div>
                    </div>
                </S.Body>
            </PerfectScrollbar>
        </S.Container>
}