import { useEffect, useCallback, useState } from 'react';
import {Carousel} from 'react-responsive-carousel';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Template from "../../template";
import * as S from './style';
import { useAuth } from "../../contexts/AuthContext";
import PageLoaderComponent from "../../components/PageLoader";
import rank from '../../assets/img/rank/escudo-laranja.png';
import api from '../../services/api';
import versus from '../../assets/img/achievement/banner/vsicon.png';
import trofeu from '../../assets/img/achievement/banner/premio-icon.png';
import copoDeSuco from '../../assets/img/copo-de-suco.png'
import jarraDeSuco from '../../assets/img/jarra-laranja.png'
import frutaLaranja from '../../assets/img/fruta-laranja.png'
import bannerRanking2 from '../../assets/img/banner/ranking-2.png'
import popupRanking from '../../assets/img/banner/pop-up-ranking-2.png'
import { useCampaign } from '../../contexts/CampaignContext'
import { useModal } from '../../contexts/ModalContext'
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Perfil (props) {
  const { user, catalogUrl, doLogout, userCan } = useAuth();
  const { campaignData } = useCampaign()
  const { openModal, closeModal } = useModal()
  const [dashboardData, setDashboardData] = useState({});
  
  const loadDashboard = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard`);
      setDashboardData(response.data.my_position);
    } catch (err) {
      if(axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: "colored" });
          doLogout();
          return;
        }
      }
    }
  }, [doLogout]);

  useEffect(() => {
    loadDashboard();

    openModal({
      header: 'Atenção!',
      size: 'mid',
      body: <S.Modal>
        <Link to="ranking" onClick={closeModal}>
          <img src={popupRanking} alt="Ranking" />
        </Link>
      </S.Modal>
    })
  }, [closeModal, loadDashboard, openModal, user.roles])

  const renderBannerImgs = useCallback(() => {
    return campaignData.homeBanners?.map((banner, idx) => (
      <div key={`banner-${idx}`}>
        <img src={banner} alt='BannerHome'/>
      </div>));
  }, [campaignData]);

  if(!user || !dashboardData) return <PageLoaderComponent />;

  return (
    <>
    <Template>

      <div className="grid medium-space">
        <S.Grid>
          <div className="user-preview small animate-slide-down">
            <S.Cover className="user-preview-cover" style={{background: `linear-gradient(-45deg, var(--secondary), var(--primary))`}}>
              <h4>Bem vindo de volta!</h4>
              <small>{user.name}</small>
            </S.Cover>
            <S.UserPreviewInfo className="user-preview-info">
              
              <S.UserStats>

                <S.Stat className="user-stat">
                  <S.Rank>
                    <img src={rank} alt="posição no rank" />
                    <div><small>POSIÇÃO</small>{dashboardData.position || "0"}</div>
                  </S.Rank>
                </S.Stat>
              </S.UserStats>

            </S.UserPreviewInfo>
            <S.Solo>
              <small className='results'>Em breve seus resultados</small>
            </S.Solo>
          </div>

          <S.SliderHome>
            <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={5000}>
                {renderBannerImgs()}
                <div>
                  <S.CarouselAnchor href="https://vitaminesuasvendascomtang.com.br/ranking">
                    <img src={bannerRanking2} alt='BannerHome' />
                  </S.CarouselAnchor>
                </div>
            </Carousel>
          </S.SliderHome>

          {/* <S.Widget className="widget-box" style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}>
            <img src={logoAlta} alt="Logo da campanha" />
          </S.Widget> */}

          <S.GridArea>
            { userCan('marketplace:read') && <S.Banner to="">
              <S.IconContainer>
                <S.Icon alt='Troféu' src={trofeu} />
              </S.IconContainer>

              <S.BannerTitle>
                Acesse o catálogo de prêmios
              </S.BannerTitle>

              <S.BannerLink href={catalogUrl}></S.BannerLink>
            </S.Banner>}

            { userCan('kpi:read') && <S.Banner to="">
              <S.IconContainer>
                <S.Icon alt='Vs' src={versus} />
              </S.IconContainer>

              <S.BannerTitle>
                Faça sua Jogada extra
              </S.BannerTitle>

              <S.BannerButton to="/quiz"></S.BannerButton>
            </S.Banner>}
          </S.GridArea>

          <S.GridFastAcccess>
            {userCan('newsfeed:read') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} to="/perfil/newsfeed">
              <S.FastLinkImage src={copoDeSuco} alt="Copo de Suco" />
              <p className="product-category-box-title">Canal de notícias</p>

              <p className="product-category-box-text">Vejas as novidades da campanha</p>
            </S.FastLink>}
            {userCan('photo:read') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} to="/perfil/photos">
              <S.FastLinkJarra src={jarraDeSuco} alt="Jarra de Suco" />
              <p className="product-category-box-title">Envio de fotos</p>

              <p className="product-category-box-text">Confira as imagens</p>
            </S.FastLink>}
            <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} to="/fale-conosco">
              <S.FastLinkFruta src={frutaLaranja} alt="Laranjas" />
              <p className="product-category-box-title">Fale conosco</p>

              <p className="product-category-box-text">Envie-nos uma mensagem</p>
            </S.FastLink>
          </S.GridFastAcccess>
        </S.Grid>

        <S.Widget className="widget-box">
          <iframe style={{width: '100%', aspectRatio: '1672 / 941', display: 'block'}} src="https://player.vimeo.com/video/769964099?h=43f8d5178d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </S.Widget>
      </div>

    </Template >
  </>
  )
}