import { useCallback } from "react";
import { useCampaign } from "../../../contexts/CampaignContext";
import { useMessages } from "../../../contexts/useMessages";
import HexagonComponent from "../../Hexagon";

function ContactCard({ contact }) {
  const { campaignData } = useCampaign();
  const { selectContact, contacts, activeContact } = useMessages();
  const changeConversation = useCallback((evt) => {
    evt.preventDefault()
    selectContact(contact.id, contacts)
  }, [selectContact, contact.id, contacts])

  return (
    <div onClick={changeConversation} className={`chat-widget-message ${activeContact?.id === contact.id ? 'active' : ''}`}>
      <div className="user-status">
        <div className="user-status-avatar">
          <div className="user-avatar small no-outline">
            <HexagonComponent src={(contact?.photo && contact?.photo !== "null") ? `${process.env.REACT_APP_IMG_URL}${contact?.photo}` : campaignData?.defaultAvatar} />
          </div>
        </div>
        <p className="user-status-title"><span className="bold">{contact?.name ?? contact?.phone ?? 'Desconhecido'}</span></p>
        {contact?.newMessageCount ? <p className="user-status-icon user-status-text user-status-tag online">{contact?.newMessageCount}</p> : null}
      </div>
    </div>
  )
}

export default ContactCard;