import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ProfileNavContainer } from "./style";
import { useAuth } from '../../contexts/AuthContext'


/**
 * @description utiliza uma variável de escopo local (navItems) para renderizar os itens da barra de navegação.
 *              Seu funcionamento está habilitado para quando necessário fazer o scroll pelo botão ou scroll via touch
 *              podendo der utilizado em contexto desktop ou mobile.
 * 
 * @returns {React.ReactElement} retorna uma barra de navegação horizontal com scrolling
 */
export default function ProfileNav() {
    const [visibleIconsQuantity, setVisibleIconsQuantity] = useState(0);
    const [step, setStep] = useState(1);
    const navRef = useRef(null)
    const itemRef = useRef(null);
    const scrollTimerRef = useRef(null);
    const scrollBehavior = useRef(0);
    const { userCan } = useAuth()

    // JSDoc

    /**
     * @typedef {Object} IconObj
     * @property {string} title - the text to be shown at button hover
     * @property {string} url - the url to go for when clicked
     *
     * @typedef {Object.<string, IconObj>} NavItems - configure menu items using the icon name as key 
     *                                                and uses {@link IconObj} as the type for its value
     * @type {NavItems} */

    const navItems = {
        profile: {
            title: "Perfil",
            url: "/perfil"
        },
        timeline: { 
            title: "Newsfeed",
            url: "./newsfeed"
        },
        group: {
            title: "Grupos",
            url: "./groups",
            permission: 'read:groups'
        },
        photos: {
            title: "Fotos",
            url: "./photos"
        },
        marketplace: {
            title: "Marketplace",
            url: "./marketplace",
            permission: 'read:marketplace'
        },
        badges: {
            title: "Badges",
            url: "./badges",
            permission: 'read:badges'
        },
        "blog-posts": {
            title: "Blog",
            url: "./blog-posts",
            permission: 'read:blog'
        },
        store: {
            title: "Minhas compras",
            url: "./store",
            permission: 'read:marketplace'
        }
    };

    // Renderiza dinamicamente os ícones de navegação de acordo com navItems
    const renderNavItems = () => {
        return Object.keys(navItems).map((navItem, idx) => {
            console.log(navItems[navItem].permission, !userCan(navItems[navItem].permission))
            if (navItems[navItem].permission && !userCan(navItems[navItem].permission)) return <></>
            return <Link key={navItem} className="section-menu-item tns-item" to={navItems[navItem].url} ref={idx === 1 ? itemRef : undefined} >
                <svg className={`section-menu-item-icon icon-${navItem}`}>
                    <use href={`#svg-${navItem}`}></use>
                </svg>
                <p className="section-menu-item-text">{navItems[navItem].title}</p>
            </Link>
        })
    }

    const handlePrev = () => {
        if (step !== 1) {
            // CLICK PREV - Levamos o scroll da posição atual menos o tamanho de um ícone
            navRef.current.scrollTo({
                left: `${((step - 1) * itemRef.current.clientWidth) - itemRef.current.clientWidth}`,
                behavior: 'smooth'
            })
        }
    }

    const handleNext = () => {
        if (step + visibleIconsQuantity <= Object.keys(navItems).length) {
            // CLICK NEXT - Levamos o scroll da posição atual mais o tamanho de um ícone
            navRef.current.scrollTo({
                left: (step) * itemRef.current.clientWidth,
                behavior: 'smooth'
            })
        }
    }

    useEffect(() => {
        const navigationElement = navRef.current;
        const itemElement = itemRef.current;
    
        /**
         * atualiza a quantidade de itens visualizados na barra de navegação
         */
        function handleResize() {
            const itemsDisplayed = Math.round(navigationElement.clientWidth / itemElement.clientWidth);
            setVisibleIconsQuantity(itemsDisplayed);
        }

        /**
         * utiliza o evento de scroll para atualizar o estado step para indicar 
         * para os controles se há mais ícones para mostrar
         */
        function handleScrolling() {
            // limpamos o timer caso esteja rodando na ocasião de scroll sendo arrastado ainda
            if (scrollTimerRef.current) {
                clearTimeout(scrollTimerRef.current);
            }
            // utilizamos um timeout para evitar o disparo por qualquer toque ao scroll
            scrollTimerRef.current = setTimeout(() => {
                // comparamos a posição antiga com a nova para determinar o número de items movimentados pelo scroll
                const itemsScrolled = Math.round(((navigationElement.scrollLeft - scrollBehavior.current) / (itemElement.clientWidth)));
                // verificamos que o scroll não ficou parado
                if (scrollBehavior.current !== navigationElement.scrollLeft) {
                    // atualizamos scrollBehavior com a posição atual do scroll
                    scrollBehavior.current = navigationElement.scrollLeft
                    setStep((oldValue) => oldValue + itemsScrolled)
                }
            }, 250);
        }
        // listener para resize e load para pegar a quantidade de itens visuzalizados
        window.addEventListener("resize", handleResize);
        window.addEventListener("load", handleResize);
        // listener para scroll
        navigationElement && navigationElement.addEventListener("scroll", handleScrolling);
        return () => {
            // Limpa listeners e timers ao desmontar o componente
            clearTimeout(scrollTimerRef.current)
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("load", handleResize);
            navigationElement && navigationElement.removeEventListener("scroll", handleScrolling);
        }
    });

    return (
        <ProfileNavContainer className="section-navigation">
            <div className="tns-outer">
                <div className="tns-liveregion tns-visually-hidden"></div>
            </div>
            {/* ITEMS MENU */}
            <div className="tns-ovh">
                <div className="tns-inner">
                    <div className="section-menu tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" ref={navRef} >
                        {/* NAV ITEMS - Ícones renderizados com base no dicionário (variável) navItems */}
                        {renderNavItems()}
                    </div>
                </div>
            </div>

            {/* SLIDER BTN CONTROLS */}
            <div className="slider-controls">
                {/* PREV BUTTON - Desabilitamos se estiver no primeiro ícone  */}
                <div className="slider-control left" aria-controls="section-navigation-slider" aria-disabled={step === 1} onClick={handlePrev}>
                    <svg className="slider-control-icon icon-small-arrow">
                        <use href="#svg-small-arrow"></use>
                    </svg>
                </div>
                {/* NEXT BUTTON - Desabilitamos se estiver no último ícone */}
                <div className="slider-control right" aria-controls="section-navigation-slider" aria-disabled={step + visibleIconsQuantity > Object.keys(navItems).length} onClick={handleNext}>
                    <svg className="slider-control-icon icon-small-arrow">
                        <use href="#svg-small-arrow"></use>
                    </svg>
                </div>
            </div>
        </ProfileNavContainer>
    )
}