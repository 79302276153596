import { useCallback, useEffect, useState } from 'react';
import Template from '../../template';
import InputSelect from '../../components/Forms/InputSelect';
import BannerTitleComponent from '../../components/BannerTitle';
import RankingGeralStaff from './RankingGeralStaff';
import Icon from '../../assets/img/banner/overview-icon.png';
import SeletorGR from './Seletor/GR'
import SeletorGA from './Seletor/GA'
import SeletorEX from './Seletor/EX'
import SeletorRegional from './Seletor/Regional'
import { useWorksheetDate } from '../../hooks/useWorksheetDate';
import * as S from './style'

function RankingStaff () {
  const [period, setPeriod] = useState()
  const [onda, setOnda] = useState()
  const [gr, setGR] = useState()
  const [ga, setGA] = useState()
  const [ex, setEX] = useState()
  const [regional, setRegional] = useState()

  const load = useWorksheetDate();

  const updateOnda = useCallback(async (onda) => {
    setOnda(onda);
    setPeriod(await load(onda || 1))
  }, [load]);

  useEffect(() => {
    updateOnda(1)
  }, [updateOnda])

  return <Template>
    <BannerTitleComponent
      imgUrl={Icon}
      title="Ranking"
      subTitle=""
      imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <h2 className="section-title">Resultados</h2>
        <p>{period}</p>
      </div>
    </div>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo>
                <InputSelect label="Selecione uma etapa" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Etapa 1</option>
                  <option value="2">Etapa 2</option>
                </InputSelect>
                <SeletorRegional onChange={setRegional} />
              </S.FiltrosCargo>
              <S.FiltrosCargo>
                <SeletorGR regional={regional} onChange={(gr) => setGR(gr)} />
                <SeletorGA gr={gr} onChange={(ga) => setGA(ga)} />
                <SeletorEX ga={ga} onChange={(ex) => setEX(ex)} />
              </S.FiltrosCargo>
              <RankingGeralStaff onda={onda || 1} gr={gr} ga={ga} ex={ex} region={regional || ""} />
            </>
          </div>

        </div>
      </div>
    </div>
  </Template>
}

export default RankingStaff;
