import styled from 'styled-components';
import { generateMedia } from "styled-media-query";

const customMedia = generateMedia({
  desktop: "1200px",
  notebook: "991px",
  tablet: "768px",
  mobile: "576px",
});

export const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextAreaContainer = styled.div`
  position: relative;
`;

export const TextAreaLabel = styled.label`
  position: absolute;
  top: 0;
  left: 1rem;
  background-color: var(--white);
  padding: .5rem;
  z-index: 2;
`;

export const TextArea = styled.textarea`
  position: relative;
  top: 1rem;
  left: 0rem;
  z-index: 1;
`;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  ${customMedia.lessThan("tablet")`
        grid-template-columns: 1fr;
    `}
`;

export const FloatIcon = styled.a`
  position: fixed;
  z-index: 999;
  right: 50px;
  bottom: 20px;
`