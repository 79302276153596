import { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import Template from "../../template"
import PageLoaderComponent from "../../components/PageLoader";


import BannerTitleComponent from "../../components/BannerTitle"
import ProfileNav from "../../components/ProfileNav";
import { Outlet } from "react-router-dom";
import { useCampaign } from "../../contexts/CampaignContext";

export default function Perfil(props) {
  const [user, setUser] = useState({});
  const { campaignData } = useCampaign();

  const { data } = useFetch('/me');

  const { avatar } = JSON.parse(localStorage.getItem('user'));
  const Avatar = avatar ? `${process.env.REACT_APP_BASE_URL}${avatar.path}` : campaignData?.defaultAvatar;


  useEffect(() => {
    setUser(data);
  }, [data])

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <BannerTitleComponent
        avatar={Avatar}
        variant="profile"
        username={user.name}
      />
      <ProfileNav />
      
      {user && <Outlet context={[user, setUser]}/>}
    </Template >
  )
}