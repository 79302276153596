import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { io } from "socket.io-client"
import axios from "axios"
import { useAuth } from "../../contexts/AuthContext"
import * as S from "./style"

export default function Dropzone({ apiEndpoint, setModalBody }) {
  const [errors, setErrors] = useState([])
  const [acceptedFile, setAcceptedFile] = useState()
  const [period, setPeriod] = useState()
  const [type, setType] = useState()
  const [step, setStep] = useState()

  const [buttonText, setButtonText] = useState('Enviar planilha')
  const [disableButton, setDisableButton] = useState(false)

  const handleSend = useCallback(async () => {
    const formData = new FormData()
    formData.append('resultados', acceptedFile)
    formData.append('period', `${period} 12:00:00`)
    formData.append('type', type ?? 1)
    formData.append('step', step ?? 1)
    setButtonText('Enviando')
    setErrors([])
    setDisableButton(true)
    try {
      const token = localStorage.getItem("token")
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}${apiEndpoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`
          },
          onUploadProgress: (e) => {
            // let progress = Math.round((e.loaded * 100) / e.total);
            // setProgress(progress);
          },
        }
      )
      setAcceptedFile()
    } catch (err) {
      console.log(err)
    }
    setButtonText('Enviar planilha')
    setDisableButton(false)
  }, [acceptedFile, apiEndpoint, period, type, step])

  const onDrop = useCallback((newAcceptedFiles) => {
    const [file] = newAcceptedFiles
    setAcceptedFile(file)
  }, [])

  const onDragLeave = useCallback(
    () => {},
    []
  )

  const onDragEnter = useCallback(
    (isAccepted, isReject) => {},
    []
  )
  const onDropRejected = useCallback(
    (files) => {},
    []
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    onDropRejected,
    onDragLeave,
    onDragEnter: () => onDragEnter(isDragAccept, isDragReject),
    maxFiles: 1,
    accept:  {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  }})
  
  const [, setSocket] = useState()

  const { token } = useAuth();

  useEffect(() => {
    const ioServer = io.connect(process.env.REACT_APP_BASE_URL, {
      query: { token },
      transports: ["websocket"]
    });

    ioServer.on('import-progress', (chunk) => {
      const row = JSON.parse(chunk)
      if (row.errors.length > 0) {
        // console.log(row)
        setErrors((errors) => [row, ...errors])
      }
    });
    setSocket(ioServer);
    return () => ioServer.close();
  }, [token])

  return (
    <>
      <S.DropZone
        {...getRootProps({ isDragActive, isDragAccept, isDragReject, maxFiles: 1 })}
      >
        <input {...getInputProps()} />
        <svg style={{ width: "40px", height: "40px" }}>
          <use href="#svg-upload-xlsx" />
        </svg>
        <S.DropZoneMessage>
          É permitido apenas o uso de planilhas no formato Excel (extensão .xlsx).
        </S.DropZoneMessage>
        {acceptedFile && <p>{acceptedFile.name}</p>}
      </S.DropZone>

      <S.ButtonGroup>
        <input placeholder="Selecione a data da planilha" value={period} onChange={(evt) => setPeriod(evt.target.value)} type="date" />
        <select value={type} onChange={(evt) => setType(evt.target.value)}>
          <option value="1">Resultados parciais</option>
          <option value="2">Ranking parcial</option>
          <option value="3">Fechamento parcial</option>
          <option value="4">Fechamento</option>
        </select>
        <select value={step} onChange={(evt) => setStep(evt.target.value)}>
          <option value="1">Etapa 1</option>
          <option value="2">Etapa 2</option>
        </select>
        <button className="button primary" disabled={disableButton} onClick={handleSend}>{buttonText}</button>
      </S.ButtonGroup>

      {errors && <S.Table>
        <thead>
          <tr>
            <th>Linha</th>
            <th>Erros</th>
          </tr>
        </thead>
        {errors.slice(0, 10).map(e => <thead key={JSON.stringify(e)}>
          <tr>
            <td>{e.number}</td>
            <td>{e.errors.map(err => <p>{err}</p>)}</td>
          </tr>
        </thead>)}
      </S.Table>}
    </>
  );
}
