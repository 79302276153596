import PageLoaderComponent from "../../../../components/PageLoader"
import { useOutletContext } from "react-router-dom";
import * as S from './style'

function ProfileHome() {
  const [user] = useOutletContext();

  if (!user && "active" in user) {
    return <PageLoaderComponent />;
  }

  return (
    <div className="account-hub-content">

      <S.Container>
        <S.ProfileWidgetBox title="Informações Pessoais">
          <div className="information-line-list">
            <div className="information-line">
              <p className="information-line-title">Email</p>
              <p className="information-line-text">{user.email}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Cargo</p>
              <p className="information-line-text">{user.cargo}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CPF</p>
              <p className="information-line-text">{user?.document}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Telefone</p>
              <p className="information-line-text">{user?.cellphone}</p>
            </div>
          </div>
        </S.ProfileWidgetBox>

        {user.holding && <S.ProfileWidgetBox title="Sobre minha holding">
          <div className="information-line-list">
            <div className="information-line">
              <p className="information-line-title">Nome</p>
              <p className="information-line-text">{user.holding.name}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CNPJ</p>
              <p className="information-line-text">{user.holding.cnpj}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Regional</p>
              <p className="information-line-text">{user.holding.regional_name}</p>
            </div>
          </div>
        </S.ProfileWidgetBox>}

        {user.distributor && <S.ProfileWidgetBox title="Sobre meu distribuidor">
          <div className="information-line-list">
            <div className="information-line">
              <p className="information-line-title">Nome</p>
              <p className="information-line-text">{user.distributor.name}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">CNPJ</p>
              <p className="information-line-text">{user.distributor.cnpj}</p>
            </div>
            <div className="information-line">
              <p className="information-line-title">Regional</p>
              <p className="information-line-text">{user.distributor.regional_name}</p>
            </div>
          </div>
        </S.ProfileWidgetBox>}

      </S.Container>
    </div>

  )
}

export default ProfileHome;