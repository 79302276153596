import * as S from './style'

function FooterComponent () {
  return <S.Footer>
    <p>Consulte os Produtos Tang e Fresh Participantes, Regras e Formas de Participação, Prêmios, e Regulamentos aqui.</p>
    <p>CERTIFICADO DE AUTORIZAÇÃO SEAE/ME Nº 03.022242/2022. Período de cadastros válidos: de 09.09.2022 a 16.12.2022.</p>
    <p>* Campanha Destinada as Redes (CNPJ) Prêmio entregue em Cartões emitidos para o CNPJ do Participante ganhador e vinculado ao CPF do respectivo representante legal</p>
    <p>Imagens meramente ilustrativas.</p>
  </S.Footer>
}

export default FooterComponent
