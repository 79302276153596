import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify"
import { GlobalStyle } from './styles'
import Router from './route';
import { Provider } from "react-redux"
import { store, persistor } from "./store"
import { PersistGate } from 'redux-persist/integration/react';
import Cookie from './components/Cookie'
import '@fortawesome/fontawesome-free/css/all.min.css'

import history from "./services/history"

import AuthProvider from './contexts/AuthContext';
import MenuProvider from "./contexts/MenuContext"
import ModalProvider from "./contexts/ModalContext"
import LockedModalProvider from "./contexts/LockedModalContext"
import NotifyProvider from './contexts/NotifyContext';
import CampaignProvider from './contexts/CampaignContext';
import MessagesProvider from './contexts/useMessages';

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <AuthProvider>
          <PersistGate loading={null} persistor={persistor}>
            <CampaignProvider>
              <MessagesProvider>
                <NotifyProvider>
                  <ModalProvider>
                    <LockedModalProvider>
                      <MenuProvider>
                        <Router />
                        <ToastContainer autoClose={3000} />
                        <GlobalStyle />
                      </MenuProvider>
                    </LockedModalProvider>
                  </ModalProvider>
                </NotifyProvider>
              </MessagesProvider>
            </CampaignProvider>
          </PersistGate>
        </AuthProvider>
      </BrowserRouter>

      <Cookie />
    </Provider>
  );
}

