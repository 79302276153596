import { Fragment } from 'react';

// components
import HeaderComponent from '../components/Header'
import FooterComponent from '../components/FooterComponent'

export default function Template ({ children }) {
    return (
        <Fragment>
            <HeaderComponent />
            <div className="content-grid">
                {children}
            </div>
            <FooterComponent />
        </Fragment>
    )
}