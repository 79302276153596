import React, { useContext, useState, useCallback, useRef } from "react";
import * as S from './styled';

const ModalContext = React.createContext();

export default function ModalProvider ({ children }) {
    const [modalState, setModalState] = useState('');
    const [header, setHeader] = useState('');
    const [body, setBody] = useState('');
    const [size, setSize] = useState('small');
    const modalRef = useRef(null);
    const modalCotentRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (modalCotentRef.current && !modalCotentRef.current.contains(event.target)) {
            setModalState('closing');
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [modalCotentRef]);

    const closeModal = useCallback((callback) => {
        setModalState('closing');
        document.removeEventListener("mousedown", handleClickOutside);
        if (callback) {
            modalRef.current.addEventListener('animationend', () => {
                callback();
            }, { once: true })
        }
    }, [handleClickOutside]);

    const openModal = useCallback(({header, body, size}) => {
        setModalState('opening');
        setHeader(header);
        setBody(body);
        setSize(size || "small");
        document.addEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <S.Container className={modalState} ref={modalRef}>
                <S.Content ref={modalCotentRef} size={size}>
                    <div className="popup-close-button popup-picture-trigger" onClick={() => closeModal()}>
                        <svg className="popup-close-button-icon icon-cross">
                            <use xlinkHref="#svg-cross"></use>
                        </svg>
                    </div>
                    <S.Header>{header}</S.Header>
                    <S.Body>{body}</S.Body>
                </S.Content>
            </S.Container>
        </ModalContext.Provider>
    );
};

export function useModal () {
    const context = useContext(ModalContext);

    if (!context) throw new Error("useCount must be used within a MenuProvider");

    const { openModal, closeModal } = context;

    return { openModal, closeModal };
}
