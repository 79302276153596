import styled from "styled-components"

export const Container = styled.div`
  &>div {
    display: flex;
    align-items: center;
  }
`

export const Checkbox = styled.input`
  margin-right: .5rem;
`

export const Label = styled.label`
  user-select: none;
`
