import { useState } from 'react';
import { Link } from 'react-router-dom'
import Template from '../../template';
import BannerTitle from '../../components/BannerTitle';
import Icon from '../../assets/img/banner/overview-icon.png';
import Accordion from '../../components/Accordion';

function Faq () {
  const [activeAccordion, setActiveAccordion] = useState(-1);
  return <Template>
    <BannerTitle
        imgUrl={Icon}
        imgAlt="overview-icon"
        title="FAQ"
        subTitle="As dúvidas mais frequêntes são encontradas aqui!"
      />
  
    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
        <h2 className="section-title">{""}</h2>
      </div>
    </div>

    <div className="widget-box">
      
  
      <p style={{fontSize: '24px', padding: '3% 15%'}} className="widget-box-title">
        Confira abaixo as perguntas frequentes e caso permaneça com alguma dúvida, entre em contato através de um chamado no campo ao final da ela.
      </p>
  
      <div className="widget-box-content">
        <div className="simple-accordion-list">
          <Accordion
              index={0}
              question="1. Qual é a Mecânica de Participação da Campanha?"
              isOpen={activeAccordion === 0}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
          >A mecânica se enquadra na modalidade Concurso Ranking por Regional. Campanha regulamentada pela SEAE.<br /><Link to="/regulamento">Regulamento</Link></Accordion>

          <Accordion
              index={1}
              question="2. Qual a Abrangência da Campanha?"
              isOpen={activeAccordion === 1}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
          >Nacional.</Accordion>
          
          <Accordion
              index={2}
              question="3. Qual o Período de participação da Campanha?"
              isOpen={activeAccordion === 2}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
          >de 09/09/22 a 31/12/22</Accordion>
  
          <Accordion
              index={3}
              question="4. Qual o cronograma de datas da Campanha?"
              isOpen={activeAccordion === 3}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              <>
                1º Bimestre:
                <>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">ACEITE – de 09/09/2022 a 30/09/2022.</p>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">VENDAS – 01/09/2022 a 31/10/2022.</p>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">ACEITE - de 01/10/2022 a 21/10/2022.</p>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">VENDAS – de 01/10/2022 a 31/10/2022</p>
                </>

                2º Bimestre:
                <>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">ACEITE – de 01/11/2022 a 22/11/2022.</p>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">VENDAS - de 01/11/2022 a 31/12/2022.</p>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">ACEITE - de 23/11/2022 a 16/12/2022.</p>
                  <p style={{fontSize: '18px'}} className="simple-accordion-text">VENDAS – de 01/12/2022 a 31/12/2022.</p>
                </>
              </>
            </Accordion>
  
          <Accordion
              index={4}
              question="5. Quais são os critérios de Participação?"
              isOpen={activeAccordion === 4}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Produtos participantes: serão considerados, exclusivamente, os produtos – Bebidas em pó, das marcas Tang e Fresh.</Accordion>

          <Accordion
              index={5}
              question="6. Quem são os participantes da Campanha?"
              isOpen={activeAccordion === 5}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >A Campanha é destinada para pessoas jurídicas - “Redes” do Canal TT (CNPJ matriz), que efetuarem o cadastro necessário e cumprirem as condições estipuladas em Regulamento.</Accordion>

          <Accordion
              index={6}
              question="7. Existe divisão por regionais?"
              isOpen={activeAccordion === 6}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Os participantes serão divididos em 5 (cinco) Regionais, no total de 560 Redes:<br />
              TT Sul: 168 redes participantes<br />
              TT SP: 175 redes participantes<br />
              TT RJ/ES/MG: 64 redes participantes<br />
              TT CO/TM/NORTE: 80 redes participantes<br />
              TT NE: 73 redes participantes<br />
          </Accordion>
  
          <Accordion
              index={7}
              question="8. O Ranking será regional?"
              isOpen={activeAccordion === 7}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Sim o Ranking será por regional.</Accordion>

          <Accordion
              index={8}
              question="9. Serão quantos premiados por regional?"
              isOpen={activeAccordion === 8}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Premiação por região:<br />
              Regional TT Sul: 80 premiados por bimestre.<br />
              Regional TT SP: 80 premiados por bimestre.<br />
              Regional TT RJ/ES/MG: 35 premiados por bimestre.<br />
              Regional TT CO/TM/NORTE: 35 premiados por bimestre.<br />
              Regional TT NE: 35 premiados por bimestre.
            </Accordion>

          <Accordion
              index={9}
              question="10. A Campanha será mensal ou bimestral"
              isOpen={activeAccordion === 9}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              A Campanha será bimestral com 2 (duas) Ondas distintas:<br />
              - 1ª Onda: setembro/outubro de 2022<br />
              - 2ª Onda: novembro/dezembro de 2022.
            </Accordion>

          <Accordion
              index={10}
              question="11. Como serão as metas?"
              isOpen={activeAccordion === 10}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Cada rede terá a sua própria meta . Caso a rede não atinja a meta do bimestre, não pontuará e não participará do ranking. A pontuação de vendas de cada Participante será zerada de um bimestre para o outro.<br />
              Centro de Distribuição (CD da rede) do Participante, as suas vendas somam na pontuação, exceto pontuação aceleradora
          </Accordion>

          <Accordion
              index={11}
              question="12. Como serão os critérios?"
              isOpen={activeAccordion === 11}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Os Participantes serão avaliados bimestralmente e acumularão pontos, de acordo com os seguintes critérios:<br />
              1. CHAVE DE ENTRADA: Cada rede receberá uma meta de sell-in de Tang e Fresh<br />
              Atingiu a meta, ganha 100 PONTOS. QUEM NÃO BATEU A META DA CHAVE DE ENTRADA NÃO PONTUA NA CAMPANHA.<br />
              2. PONTUAÇÃO: Cada ponto percentual (1%) acima da meta + 10 PONTOS<br />
              3. ACELERADOR: o envio de registro por foto do Ponto Extra, limitada a 3 Pontos Extras/por loja/por mês, no total de 6 fotos por bimestre:<br />
              Definição de ponto extra:  Vide Regulamento (colocar Link)
          </Accordion>

          <Accordion
              index={12}
              question="13. De quem é a responsabilidade pelo envio das fotos de Ponto Extra? Quais os períodos de envio?"
              isOpen={activeAccordion === 12}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              A responsabilidade pelo upload das fotos dos Pontos Extras das lojas será do respectivo Distribuidor da rede Participante, de uma ÚNICA vez, mensalmente, dentro do bimestre, observados os períodos abaixo:<br />
              Bimestre setembro/outubro de 2022: De 12/09/2022 a 07/10/2022 e De 01/10/2022 a 21/10/2022<br />
              Bimestre novembro/dezembro de 2022: De 01/11/2022 a 30/11/2022 e De 01/12/2022 a 16/12/2022
          </Accordion>

          <Accordion
              index={13}
              question="14. Como funciona a pontuação das fotos?"
              isOpen={activeAccordion === 13}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              1 Ponto Extra = vale 100 pontos.<br />
              Limite de 3 Pontos Extras/mês, sendo 6 Pontos Extras por Bimestre.<br />
              Média de 1 PONTO EXTRA por loja e, a partir da média de 1 ponto Extra, a pontuação será proporcional.<br />
              Exemplo: Rede tem 15 lojas<br />
              10 lojas tiveram 2 pontos extras = 20<br />
              3 lojas tiveram 3 pontos extras = 9<br />
              2 lojas tiveram 0 pontos extras = 0<br />
              29/ 15 = média de 1,93 pontos extra por loja x100 = 193 pontos
            </Accordion>

          <Accordion
              index={14}
              question="15. O ponto extra pode ter a marca fresh?"
              isOpen={activeAccordion === 14}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Sim, desde que respeite a divisão 70% tang e 30% fresh, e com comunicação de preço bem destacada.
          </Accordion>

          <Accordion
              index={15}
              question="16. O que é considerado ponto extra?"
              isOpen={activeAccordion === 15}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Será considerado ponto extra pontas de gondola e ilhas.
          </Accordion>

          <Accordion
              index={16}
              question="17. Pontos extras serão avaliados mensalmente ou no final do bimestre?"
              isOpen={activeAccordion === 16}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Serão avaliados mensalmente.</Accordion>

          <Accordion
              index={17}
              question="18. Quais são os valores dos prêmios?"
              isOpen={activeAccordion === 17}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Os prêmios variam de R$ 4.000,00 a R$ 20.000,00 conforme Regulamento.</Accordion>

          <Accordion
              index={18}
              question="19. Como são entregues os prêmios?"
              isOpen={activeAccordion === 18}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              Após as verificações no prazo máximo de 30 (trinta) dias, contados das datas de entregas da documentação completa e válida pelos contemplados, de forma gratuita e sem ônus, os cartões serão<br />
              emitidos para o CNPJ do Participante ganhador e vinculado ao CPF do respectivo representante legal.
            </Accordion>

          <Accordion
              index={19}
              question="20. Redes que participam do programa loja perfeita, podem participar?"
              isOpen={activeAccordion === 19}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Sim, porém terão metas mais agressivas de crescimento.</Accordion>

          <Accordion
              index={20}
              question="21. Redes que possuem lojas de outro perfil além de 5+cks, podem participar?"
              isOpen={activeAccordion === 20}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Sim, podem participar, mas a maioria das lojas da rede precisam ser predominantemente 5+cks.</Accordion>

        </div>
      </div>
    </div>
  </Template>;
}

export default Faq;