import Template from "../../template";
import PageLoader from '../../components/PageLoader';
import bannerIcon from '../../assets/img/banner/overview-icon.png';
import { useAuth } from '../../contexts/AuthContext';
import BannerTitleComponent from '../../components/BannerTitle'

function Regulamento() {
  const { user } = useAuth();

  if (!user) return <PageLoader />

  return <Template>
    <BannerTitleComponent
        imgUrl={bannerIcon}
        title="POLÍTICAS DE PRIVACIDADE"
        subTitle=""
        imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>

        <h2 className="section-title">&nbsp;</h2>
      </div>
    </div>

    <div className="widget-box">
      <p style={{ fontSize: '24px', padding: '3% 15%', textAlign: 'center' }} className="widget-box-title">TERMOS DE USO</p>
      <div className="widget-box-content">

        <h2>REGULAMENTO/PLANO DE OPERAÇÃO DA PROMOÇÃO</h2>

        <h3>Campanha Vitamine suas Vendas com Tang</h3>

        <br />

        <h4>1 - EMPRESAS PROMOTORAS:</h4>

        <p>1.1 - Empresa Mandatária:</p>
        <p>Razão Social: MONDELEZ BRASIL LTDA
        </p>
        <p>Endereço: PRESIDENTE KENNEDY Número: 2511 Complemento: PARTE Bairro: AGUA VERDE Município:
            CURITIBA UF: PR CEP:80610-010
        </p>
        <p>CNPJ/MF nº: 33.033.028/0001-84
        </p>

        <h4>2 - MODALIDADE DA PROMOÇÃO:
        </h4>

        <p>Concurso</p>
        
        <h4>3 - ÁREA DE ABRANGÊNCIA:
        </h4>

        <p>Todo o território nacional.</p>

        <h4>4 - PERÍODO DA PROMOÇÃO:
        </h4>

        <p>09/09/2022 a 30/01/2023</p>

        <h4>5 - PERÍODO DE PARTICIPAÇÃO:
        </h4>
        
        <p>09/09/2022 a 31/12/2022</p>

        <h4>6 - CRITÉRIO DE PARTICIPAÇÃO:
        </h4>

        <p>6.1. Produtos participantes. Serão considerados, exclusivamente, os produtos – Bebidas em pó, das marcas Tang e
            Fresh [“Produtos participantes”].
        </p>

        <p>6.2. PARTICIPANTES: A Campanha é destinada para pessoas jurídicas - “Redes” do Canal TT (CNPJ matriz),
            adiante denominados apenas como “Participantes” que efetuarem o cadastro necessário e cumprirem as condições
            estipuladas em Regulamento.
        </p>

        <p>6.2.1. Os Participantes serão divididos em 5 (cinco) Regionais, no total de 560 Redes:
        </p>

        <p>TT Sul: 168 redes participantes
        </p>

        <p>TT SP: 175 redes participantes
        </p>

        <p>TT RJ/ES/MG: 64 redes participantes
        </p>

        <p>TT CO/TM/NORTE: 80 redes participantes
        </p>

        <p>TT NE: 73 redes participantes
        </p>

        <p>6.2.2. Observada a divisão por Regional, para fim de participação e premiação, os Participantes serão dispostos em
            Ranking por Regional.
        </p>

        <p>6.2.3. A Campanha será bimestral com 2 (duas) Ondas distintas:
        </p>

        <p>- 1ª Onda: setembro/outubro de 2022
        </p>

        <p>- 2ª Onda: novembro/dezembro de 2022.
        </p>

        <p>Haverá relatório mensal das participações e, ao final de cada bimestre, haverá a consolidação dos resultados.
        </p>

        <p>De acordo com o disposto acima, haverá o seguinte cronograma de execução, a saber:
        </p>

        <p>CRONO DA CAMPANHA – PARTICIPAÇÃO/ACEITES
        </p>

        <p>1º Bimestre:
        </p>

        <p>•	CADASTRO E ACEITE - (PERÍODO DE 29 DIAS) – de 09/09/2022 a 07/10/2022.
        </p>

        <p>•	CONTABILIZAÇÃO DAS VENDAS – (PERÍODO DE 61 DIAS CORRIDOS) – 01/09/2022 a 31/10/2022.
        </p>

        <p>•	CADASTRO E ACEITE - (PERÍODO DE 14 DIAS) – de 08/10/2022 a 21/10/2022. O PARTICIPANTE QUE SE CADASTRAR, A PARTIR DE 08/10/2022, NÃO TERÁ SEUS RESULTADOS DE SETEMBRO CONTABILIZADOS
            PARA A CAMPANHA, SOMENTE OS RESULTADOS, A PARTIR DE 01/10/2022.
        </p>

        <p>•	CONTABILIZAÇÃO DAS VENDAS – (PERÍODO DE 31 DIAS CORRIDOS) - de 01/10/2022 a 31/10/2022.
        </p>

        <br />

        <p>2º Bimestre:
        </p>

        <p>•	CADASTRO E ACEITE – (PERÍODO DE 22 DIAS) – de 01/11/2022 a 22/11/2022.
        </p>

        <p>•	CONTABILIZAÇÃO DAS VENDAS - (PERÍODO DE 61 DIAS) – de 01/11/2022 a 31/12/2022.
        </p>

        <p>•	CADASTRO E ACEITE - (PERÍODO DE 24 DIAS) – de 23/11/2022 a 16/12/2022. O PARTICIPANTE QUE SE
            CADASTRAR, A PARTIR DE 23/11/2022, NÃO TERÁ SEUS RESULTADOS DE NOVEMBRO CONTABILIZADOS
            PARA A CAMPANHA, SOMENTE OS RESULTADOS, A PARTIR DE 01/12/2022.
        </p>

        <p>•	CONTABILIZAÇÃO DAS VENDAS – (PERÍODO DE 31 DIAS CORRIDOS) - de 01/12/2022 a 31/12/2022.
        </p>

        <p>•	Os Participantes que se cadastrarem no 1º Bimestre, estarão aptos a participar durante toda a vigência da
            Campanha, todavia, suas pontuações do 1º bimestre serão zeradas para que possam pontuar novamente na etapa
            subsequente.
        </p>

        <p>6.2.4. MECÂNICA E Premiação - Concurso – Premiação por região – x primeiros colocados.
            Independentemente do tamanho da Rede: P, M ou G, todos os participantes concorrem dentro de uma mesma
        categoria (regional).
        </p>

        <p>Premiação por região:
        </p>

        <p>Regional TT Sul: 80 premiados por bimestre.
        </p>

        <p>Regional TT SP: 80 premiados por bimestre.
        </p>

        <p>Regional TT RJ/ES/MG: 35 premiados por bimestre.
        </p>

        <p>Regional TT CO/TM/NORTE: 35 premiados por bimestre.
        </p>

        <p>Regional TT NE: 35 premiados por bimestre.
        </p>

        <p>Cada rede terá a sua própria meta estabelecida pela Promotora.
        </p>

        <p>Caso a rede não atinja a meta do bimestre, não pontuará e não participará do ranking.
        </p>

        <p>A pontuação de vendas de cada Participante será zerada de um bimestre para o outro.
        </p>

        <p>Centro de Distribuição (CD da rede) do Participante, as suas vendas somam na pontuação, exceto pontuação
            aceleradora, pois não possuem local para exposição de produtos participantes, conforme previsto em
            Regulamento.
        </p>

        <p>Critérios de Avaliação:
        </p>

        <p>Os Participantes serão avaliados bimestralmente e acumularão pontos, de acordo com os seguintes critérios:
        </p>

        <p>1. CHAVE DE ENTRADA
        </p>

        <p>Cada rede receberá uma meta de sell-in de Tang e Fresh
        </p>

        <p>Atingiu a meta, ganha 100 PONTOS.
        </p>

        <p>QUEM NÃO BATEU A META DA CHAVE DE ENTRADA NÃO PONTUA NA CAMPANHA.
        </p>

        <p>2. PONTUAÇÃO:
        </p>

        <p>Cada ponto percentual (1%) acima da meta
        </p>

        <p>+ 10 PONTOS
        </p>

        <p>3. ACELERADOR:
        </p>

        <p>Acelerador por positivação de Ponto Extra por loja que significa o envio de registro por foto do Ponto Extra, limitada
            a 3 Pontos Extras/por loja/por mês, no total de 6 fotos por bimestre:
        </p>

        <p>Definição de ponto extra: Por ponto extra será considerado ilha e terminal de gôndola presentes na loja.
        </p>

        <p>Será permitida a postagem de apenas uma ÚNICA foto por Ponto Extra (ilha ou terminal de gôndola).
        </p>

        <p>CRITÉRIOS de qualificação DA FOTO: válido para ilha ou terminal de gôndola
        </p>

        <p>- Preço visível
        </p>

        <p>- Dentro do range
        </p>

        <p>- Presença do msl (llammu)
        </p>

        <p>- Mpdv
        </p>

        <p>CRITÉRIOS PARA ACEITAÇÃO DA FOTO:
        </p>

        <p>- Código RANDOMICO Válido
        </p>

        <p>- FOTO nítida
        </p>

        <p>- PONTO EXTRA DO(S) PRODUTO(S) PARTICIPANTE(S)
        </p>

        <p>A responsabilidade pelo upload das fotos dos Pontos Extras das lojas será do respectivo Distribuidor da rede
            Participante, de uma ÚNICA vez, mensalmente, dentro do bimestre, observados os períodos abaixo:
        </p>

        <p>Bimestre setembro/outubro de 2022:
        </p>

        <p>De 12/09/2022 a 07/10/2022
        </p>

        <p>De 01/10/2022 a 21/10/2022
        </p>

        <p>	Bimestre novembro/dezembro de 2022:
        </p>

        <p>De 01/11/2022 a 30/11/2022
        </p>

        <p>De 01/12/2022 a 16/12/2022
        </p>

        <p>Diante do exposto acima, teremos a pontuação do Acelerador, a saber:
        </p>

        <p>Se a loja tiver 1 Ponto Extra = 100 pontos.
        </p>

        <p>A loja que não tem nenhum Ponto Extra = 0 ponto.
        </p>

        <p>A loja que possuir 2 Pontos Extras = 200 pontos.
        </p>

        <p>A loja que possuir 3 Pontos Extras = 300 pontos.
        </p>

        <p>Por loja haverá o limite de 3 Pontos Extras/mês, sendo 6 Pontos Extras.
        </p>

        <p>Média de 1 PONTO EXTRA por loja e, a partir da média de 1 ponto Extra, a pontuação será proporcional.
        </p>

        <p>Desta forma, o resultado dos pontos do Acelerador será calculado pelo número de Pontos Extras dividido pela
            quantidade de lojas da rede. Esse total deverá ser multiplicado por 100.
        </p>

        <p>Exemplo: Rede tem 15 lojas
        </p>

        <p>10 lojas tiveram 2 pontos extras = 20
        </p>

        <p>3 lojas tiveram 3 pontos extras = 9
        </p>

        <p>2 lojas tiveram 0 pontos extras = 0
        </p>
        
        <p>29/ 15 = média de 1,93 pontos extra por loja x100 = 193 pontos
        </p>

        <p>6.3. Forma de Participação. Para participar, os Participantes deverão acessar a URL:
            vitaminesuasvendascomtang.com.br e seguir as instruções para a inscrição que aparecerão na tela, onde estarão
            disponíveis o Regulamento completo e os campos de cadastro que deverão ser preenchidos com os dados da
            empresa, obrigatórios, que permitirão sua clara identificação e rápida localização:
        </p>

        <p>CNPJ
        </p>

        <p>Razão Social
        </p>

        <p>Nome empresa
        </p>

        <p>Nome pessoa
        </p>

        <p>E-mail
        </p>

        <p>E-mail alternativo
        </p>

        <p>Cargo
        </p>

        <p>Celular Corporativo
        </p>

        <p>6.3.1. O Participante deverá preencher completamente a ficha de cadastro de participação na presente Campanha
            e concordar expressamente, através do campo “Regulamento” com todos os termos e condições deste
            Regulamento e dar aceite único para os 2 (dois) bimestres de participação da Campanha, para, assim, concluir sua
            inscrição.
        </p>

        <p>6.3.2. Conforme disposto no item 6.2, somente participam desta Campanha e as Redes do Canal TT que se
            cadastrarem na URL: vitaminesuasvendascomtang.com.br e cumprirem todos os requisitos descritos este
            Regulamento.
        </p>

        <p>6.3.3. O simples ato da inscrição na Campanha pressupõe total conhecimento e concordância com as disposições
            deste Regulamento por parte do Participante.
        </p>

        <p>6.3.4. O Participante, no momento em que inicia sua participação na ação e dá o seu de acordo com os termos
            deste Regulamento e na Política de Privacidade disponível a URL: vitaminesuasvendascomtang.com.br,
            consentindo/autorizando a utilização dos seus dados pessoais com a finalidade específica para uso nesta
            Campanha, nos termos deste Regulamento.
        </p>

        <p>6.4. A contabilização dos pontos da Campanha ocorrerá em até 30 (trinta) dias corridos do término de cada
            bimestre, os pontos serão somados e será gerado Ranking Bimestral e em ordem decrescente, da maior para
            menor pontuação geral, para premiar os melhores Participantes, de cada Regional, conforme informações abaixo:
        </p>

        <p>Mecânica Ranking Bimestral
        </p>

        <p>Região: TT SUL
        </p>

        <p>Quantidade de Redes Participantes: 168
        </p>

        <p>Quantidade de Redes Premiadas: 80
        </p>

        <p>Ranking (posição)/Prêmio Valor Unitário/Quantidade Prêmios/Valor Total Prêmios
        </p>

        <p>1º colocado: R$ 20.000,00, sendo 1 prêmio, no valor de R$ 20.000,00. TOTAL: R$ 20.000,00.
        </p>

        <p>2º ao 10º colocados: R$ 10.000,00, cada, sendo 9 prêmios no valor de R$ 10.000,00, cada. TOTAL: R$ 90.000,00.
        </p>

        <p>11º ao 30º colocados: R$ 8.000,00, cada, sendo 20 prêmios, no valor total de R$ 8.000,00, cada. TOTAL: 160.000,00
        </p>
        
        <p>31º ao 80º colocados: R$ 4.000,00, cada, sendo 50 prêmios, no valor de R$ 4.000,00, cada. TOTAL: R$ 200.000,00
        </p>

        <p>Região: TT SP
        </p>

        <p>Quantidade de Redes Participantes: 175
        </p>

        <p>Quantidade de Redes Premiadas: 80
        </p>

        <p>Ranking (posição)/Prêmio Valor Unitário/Quantidade Prêmios/Valor Total Prêmios
        </p>

        <p>1º colocado: R$ 20.000,00, sendo 1 prêmio, no valor de R$ 20.000,00. TOTAL: R$ 20.000,00.
        </p>

        <p>2º ao 10º colocados: R$ 10.000,00, cada, sendo 9 prêmios no valor de R$ 10.000,00, cada. TOTAL: R$ 90.000,00.
        </p>

        <p>11º ao 30º colocados: R$ 8.000,00, cada, sendo 20 prêmios, no valor total de R$ 8.000,00, cada. TOTAL:
            160.000,00.
        </p>

        <p>31º ao 80º colocados: R$ 4.000,00, cada, sendo 50 prêmios, no valor de R$ 4.000,00, cada. TOTAL: R$ 200.000,00
        </p>

        <p>Região: TT RJ / ES / MG
        </p>

        <p>Quantidade de Redes Participantes: 64
        </p>

        <p>Quantidade de Redes Premiadas: 35
        </p>

        <p>Ranking (posição)/Prêmio Valor Unitário/Quantidade Prêmios/Valor Total Prêmios
        </p>

        <p>1º colocado: R$ 20.000,00, sendo 1 prêmio, no valor de R$ 20.000,00. TOTAL: R$ 20.000,00.
        </p>

        <p>2º ao 5º colocados: R$ 10.000,00, cada, sendo 4 prêmios no valor de R$ 10.000,00, cada. TOTAL: R$ 40.000,00
        </p>

        <p>6º ao 15º colocados: R$ 8.000,00, cada, sendo 10 prêmios, no valor total de R$ 8.000,00, cada. TOTAL: R$
            80.000,00.
        </p>

        <p>16º ao 35º colocados: R$ 4.000,00, cada, sendo 20 prêmios, no valor de R$ 4.000,00, cada. TOTAL: R$ 80.000,00.
        </p>

        <p>Região: TT CO / TM / NORTE
        </p>

        <p>Quantidade de Redes Participantes: 80
        </p>

        <p>Quantidade de Redes Premiadas: 35
        </p>

        <p>Ranking (posição)/Prêmio Valor Unitário/Quantidade Prêmios/Valor Total Prêmios
        </p>

        <p>1º colocado: R$ 20.000,00, sendo 1 prêmio, no valor de R$ 20.000,00. TOTAL: R$ 20.000,00.
        </p>

        <p>2º ao 5º colocados: R$ 10.000,00, cada, sendo 4 prêmios no valor de R$ 10.000,00, cada. TOTAL: R$ 40.000,00
        </p>

        <p>6º ao 15º colocados: R$ 8.000,00, cada, sendo 10 prêmios, no valor total de R$ 8.000,00, cada. TOTAL: R$
            80.000,00.
        </p>

        <p>16º ao 35º colocados: R$ 4.000,00, cada, sendo 20 prêmios, no valor de R$ 4.000,00, cada. TOTAL: R$ 80.000,00.
        </p>

        <p>Região: TT NE
        </p>

        <p>Quantidade de Redes Participantes: 73
        </p>

        <p>Quantidade de Redes Premiadas: 35
        </p>

        <p>Ranking (posição)/Prêmio Valor Unitário/Quantidade Prêmios/Valor Total Prêmios
        </p>

        <p>1º colocado: R$ 20.000,00, sendo 1 prêmio, no valor de R$ 20.000,00. TOTAL: R$ 20.000,00.
        </p>

        <p>2º ao 5º colocados: R$ 10.000,00, cada, sendo 4 prêmios no valor de R$ 10.000,00, cada. TOTAL: R$ 40.000,00
        </p>

        <p>6º ao 15º colocados: R$ 8.000,00, cada, sendo 10 prêmios, no valor total de R$ 8.000,00, cada. TOTAL: R$
            80.000,00.
        </p>

        <p>16º ao 35º colocados: R$ 4.000,00, cada, sendo 20 prêmios, no valor de R$ 4.000,00, cada. TOTAL: R$ 80.000,
            00.
        </p>

        <p>6.5. Os pontos adquiridos e computados para cada Participante são intransferíveis e não se acumulam de um
            bimestre para o outro, durante o período da Campanha.
        </p>

        <p>6.6. Armazenamento de dados e Privacidade. Todos os cadastros serão armazenados num banco de dados único
            da Campanha que estará hospedado no servidor de terceiro fornecedor contratado, respeitando a legislação
            promocional e a Lei Geral de Proteção de Dados (Lei 13.709/2018).
        </p>

        <p>6.6.1. O Participante, quando inicia sua participação na ação e dá o seu de acordo com os termos deste
            Regulamento, consente/autoriza a utilização dos seus dados pessoais com a finalidade específica para uso 
            nesta Campanha e para ativações e informações/comunicações sobre produtos e promoções para aqueles que 
            aceitarem o “opt-in”, nos termos deste Regulamento.
        </p>

        <p>6.6.2. Todos os dados fornecidos pelos Participantes serão armazenados em banco de dados, observadas as
            medidas de segurança razoáveis disponíveis no mercado atualmente, durante o prazo de execução desta
            Campanha a fim de que seja garantida a execução da Campanha, cumprindo com todas as obrigações legais e 
            contratuais aplicáveis, e a participação do Participante de forma integral e adequada, inclusive
            envio de mensagens relativas à Campanha, bem como após o seu término pelo prazo de 5 (cinco) anos para fins
            de prevenção a fraude, cumprimento de obrigação legal ou contratual e solicitação ou defesa dos interesses 
            da Promotora perante Autoridade em âmbito administrativo ou judicial.
        </p>

        <p>6.6.3. A Promotora irá cumprir a legislação relativa à privacidade e proteção de dados pessoais em todos os 
            atos referentes à execução do presente Regulamento. A legislação pertinente inclui (mas não se limita) a 
            Lei Geral de Proteção de Dados Pessoais (“LGPD”), sendo adotada a definição de “tratamento” e “dados 
            pessoais” nelaprevista.
        </p>

        <p>6.6.4. O tratamento de seus Dados Pessoais será realizado de acordo com as bases legais previstas na LGPD. Os
            dados necessários à participação dessa Campanha serão coletados pelos seguintes motivos:
        </p>

        <p>(i)	Para cumprir com as obrigações legais da Promotora;
        </p>

        <p>(ii)	Para cumprir com obrigações contratuais da Promotora;
        </p>

        <p>(iii)	Para atendermos aos interesses legítimos da Promotora;
        </p>

        <p>(iv)	Pela obtenção consentimento do participante (quando necessário) e/ou
        </p>

        <p>(v)	Para eventual exercício de defesa em procedimentos administrativos o judiciais.
        </p>

        <p>6.6.4.1 De acordo com o disposto no item 6.6.1 “parte final”, através da base legal do consentimento, os 
            dados dos participantes poderão ser ativados para os fins ali descritos.
        </p>

        <p>6.6.5. Caso o participante queira exercer algum dos seus direitos relativos a seus dados pessoais, entre em 
            contato com nosso SAC (0800 704-1940) ou acesse o site
          <a href=" https://privacyportal-de.onetrust.com/webform/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/42251e9d-f6be-4ae0-971cf311d8047e5a"> https://privacyportal-de.onetrust.com/webform/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/42251e9d-f6be-4ae0-971cf311d8047e5a</a>
        </p>

        <p>6.7. Tendo em vista as características do ambiente da internet, a Promotora não se responsabilizará pela 
            inscrição dos participantes e pelo cadastramento dos produtos promocionais que não forem realizados por 
            problemas de conexão no servidor, nas linhas telefônicas ou em provedores de acesso dos usuários ou, ainda, 
            por falta de energia elétrica, sem exclusão das demais situações decorrentes de caso fortuito ou de força 
            maior.
        </p>

        <p>6.8. Cumprida a condição de participação, o cadastro do participante será válido, desde que respeitado o    
            período e o horário de participação, tendo como referência o horário de Brasília. Será considerado o 
            horário registrado no sistema e não o indicado no computador, e/ou dispositivo móvel (tablet ou aparelho 
            celular) do participante, uma vez que podem existir atrasos sistêmicos no tráfego e recepção de dados.
        </p>
        
        <h4>7 - APURAÇÃO E DESCRIÇÃO DE PRÊMIOS:
        </h4>

        <p>PERÍODO DA APURAÇÃO: 01/11/2022 00:00 a 30/11/2022 23:59
        </p>

        <p>PERÍODO DE PARTICIPAÇÃO DA APURAÇÃO: 09/09/2022 00:00 a 31/10/2022 23:59
        </p>

        <p>ENDEREÇO DA APURAÇÃO: Avenida Anápolis NÚMERO: 100 COMPLEMENTO: sala 613, Edifício NBC
            BAIRRO: Bairro Bethaville I
        </p>

        <p>MUNICÍPIO: Barueri UF: SP CEP: 06404-250
        </p>

        <p>LOCAL DA APURAÇÃO: Empresa de tecnologia desenvolvedora da plataforma (SITE)
        </p>

        <p>PRÊMIOS
        </p>
        
        <table class="tg">
            <thead>
              <tr>
                <th class="tg-b3sw">Quantidade</th>
                <th class="tg-b3sw">Descrição</th>
                <th class="tg-b3sw">Valor R$</th>
                <th class="tg-b3sw">Valor Total R$</th>
                <th class="tg-b3sw">Ordem</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tg-0lax">5</td>

                <td class="tg-0lax">Considerando o ranking bimestral de cada Regional: TT Sul,
                    TT SP, TT RJ/ES/MG, TT CO/TM/NORTE e TT NE, o 1º
                    colocado do ranking/por regional terá direto ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$
                    20.000,00, sem saque, com validade de 180 dias.
                </td>

                <td class="tg-0lax">20.000,00 </td>

                <td class="tg-0lax">100.000,00</td>

                <td class="tg-0lax">1</td>

              </tr>

              <tr>
                <td class="tg-0lax">30</td>

                <td class="tg-0lax">Considerando o ranking bimestral das Regionais TT Sul e TT
                    SP, do 2º ao 10º colocados do ranking/por regional terão
                    direito ao prêmio que consiste em 1 (um) Cartão-presente no
                    valor de R$ 10.000,00, sem saque, com validade de 180 dias.
                    Considerando o ranking bimestral das Regionais TT
                    RJ/ES/MG, TT CO/TM/NORTE e TT NE, do 2º ao 5º
                    colocados do ranking/por regional terão direito ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$
                    10.000,00, sem saque, com validade de 180 dias.
                </td>

                <td class="tg-0lax">10.000,00 </td>

                <td class="tg-0lax">300.000,00 </td>

                <td class="tg-0lax">2</td>

              </tr>

              <tr>
                <td class="tg-0lax">70</td>

                <td class="tg-0lax">Considerando o ranking bimestral das Regionais TT Sul e TT
                    SP, do 11º ao 30º colocados do ranking/por regional terão
                    direito ao prêmio que consiste em 1 (um) Cartão-presente no
                    valor de R$ 8.000,00, sem saque, com validade de 180 dias.
                    Considerando o ranking bimestral das Regionais TT
                    RJ/ES/MG, TT CO/TM/NORTE e TT NE, do 6º ao 15º
                    colocados do ranking/por regional terão direito ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$ 8.000,00,
                    sem saque, com validade de 180 dias.
                </td>
                <td class="tg-0lax">8.000,00</td>

                <td class="tg-0lax">560.000,00</td>

                <td class="tg-0lax">3</td>

              </tr>

              <tr>
                <td class="tg-0lax">160</td>

                <td class="tg-0lax">Considerando o ranking bimestral das Regionais TT Sul e TT
                    SP, do 31º ao 80º colocados do ranking/por regional terão
                    direito ao prêmio que consiste em 1 (um) Cartão-presente no
                    valor de R$ 10.000,00, sem saque, com validade de 180 dias.
                    Considerando o ranking bimestral das Regionais TT
                    RJ/ES/MG, TT CO/TM/NORTE e TT NE, do 16º ao 35º
                    colocados do ranking/por regional terão direito ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$ 4.000,00,
                    sem saque, com validade de 180 dias.
                </td>
                <td class="tg-0lax">4.000,00</td>
                <td class="tg-0lax">640.000,00</td>
                <td class="tg-0lax">4</td>
              </tr>
            </tbody>
        </table>

        <br />

        <p>PERÍODO DA APURAÇÃO: 01/01/2023 00:00 a 30/01/2023 23:59
        </p>

        <p>PERÍODO DE PARTICIPAÇÃO DA APURAÇÃO: 01/11/2022 00:00 a 31/12/2022 23:59
        </p>

        <p>ENDEREÇO DA APURAÇÃO: Avenida Anápolis NÚMERO: 100 COMPLEMENTO: sala 613, Edifício NBC
            BAIRRO: Bairro Bethaville I
        </p>

        <p>MUNICÍPIO: Barueri UF: SP CEP: 06404-250
        </p>

        <p>LOCAL DA APURAÇÃO: Empresa de tecnologia desenvolvedora da plataforma (SITE)
        </p>

        <p>PRÊMIOS
        </p>
        
        <table class="tg">
                <thead>
                    <tr>
                      <th class="tg-b3sw">Quantidade</th>
                      <th class="tg-b3sw">Descrição</th>
                      <th class="tg-b3sw">Valor R$</th>
                      <th class="tg-b3sw">Valor Total R$</th>
                      <th class="tg-b3sw">Ordem</th>
                    </tr>
                  </thead>
            <tbody>
              <tr>
                <td class="tg-0lax">5</td>
                <td class="tg-0lax">Considerando o ranking bimestral de cada Regional: TT Sul,
                    TT SP, TT RJ/ES/MG, TT CO/TM/NORTE e TT NE, o 1º
                    colocado do ranking/por regional terá direto ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$
                    20.000,00, sem saque, com validade de 180 dias.
                </td>
                <td class="tg-0lax">20.000,00</td>

                <td class="tg-0lax">100.000,00 </td>

                <td class="tg-0lax">1</td>

              </tr>

              <tr>
                <td class="tg-0lax">30</td>
                <td class="tg-0lax">Considerando o ranking bimestral das Regionais TT Sul e TT
                    SP, do 2º ao 10º colocados do ranking/por regional terão
                    direito ao prêmio que consiste em 1 (um) Cartão-presente no
                    valor de R$ 10.000,00, sem saque, com validade de 180 dias.
                    Considerando o ranking bimestral das Regionais TT
                    RJ/ES/MG, TT CO/TM/NORTE e TT NE, do 2º ao 5º
                    colocados do ranking/por regional terão direito ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$
                    10.000,00, sem saque, com validade de 180 dias.
                </td>

                <td class="tg-0lax">10.000,00</td>

                <td class="tg-0lax">300.000,00</td>

                <td class="tg-0lax">2</td>

              </tr>

              <tr>
                <td class="tg-0lax">70</td>

                <td class="tg-0lax">Considerando o ranking bimestral das Regionais TT Sul e TT
                    SP, do 11º ao 30º colocados do ranking/por regional terão
                    direito ao prêmio que consiste em 1 (um) Cartão-presente no
                    valor de R$ 8.000,00, sem saque, com validade de 180 dias.
                    Considerando o ranking bimestral das Regionais TT
                    RJ/ES/MG, TT CO/TM/NORTE e TT NE, do 6º ao 15º
                    colocados do ranking/por regional terão direito ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$ 8.000,00,
                    sem saque, com validade de 180 dias.
                </td>

                <td class="tg-0lax">8.000,00</td>

                <td class="tg-0lax">560.000,00</td>

                <td class="tg-0lax">3</td>

              </tr>

              <tr>
                <td class="tg-0lax">160</td>

                <td class="tg-0lax">Considerando o ranking bimestral das Regionais TT Sul e TT
                    SP, do 31º ao 80º colocados do ranking/por regional terão
                    direito ao prêmio que consiste em 1 (um) Cartão-presente no
                    valor de R$ 10.000,00, sem saque, com validade de 180 dias.
                    Considerando o ranking bimestral das Regionais TT
                    RJ/ES/MG, TT CO/TM/NORTE e TT NE, do 16º ao 35º
                    colocados do ranking/por regional terão direito ao prêmio que
                    consiste em 1 (um) Cartão-presente no valor de R$ 4.000,00,
                    sem saque, com validade de 180 dias.
                </td>

                <td class="tg-0lax">4.000,00</td>

                <td class="tg-0lax">640.000,00</td>

                <td class="tg-0lax">4</td>
              </tr>
            </tbody>
        </table>

        <br />

        <h4>8 - PREMIAÇÃO TOTAL:
        </h4>

        <table class="tg">
            <thead>
              <tr>
                <th class="tg-b3sw">Quantidade Total de Prêmios</th>
                <th class="tg-b3sw">Valor total da Promoção R$
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tg-0lax">530</td>
                <td class="tg-0lax">3.200.000,00</td>
              </tr>
            </tbody>
        </table>

        <br />

        <h4>9 - FORMA DE APURAÇÃO:
        </h4>

        <p>9.1. Avaliação das participações pela Promotora. De acordo com os critérios/pontuação estabelecidos 
            em Regulamento, ao final de cada bimestre, a Promotora fará o cômputo geral da pontuação obtida pelos 
            Participantes, durante o período de participação, com a finalidade de elaborar um ranking Bimestral e por 
            Regional, em ordem decrescente, da maior a menor pontuação.
        </p>

        <p>9.2. Em caso de empate, serão utilizados os seguintes critérios de desempate:
        </p>

        <p>1º: Sell In: quem tiver mais pontos percentuais acima da meta acordada.
        </p>

        <p>2º: Ponto Extra: quem tiver o maior número médio de pontos extras por loja.
        </p>

        <p>3º: Caso os critérios 1 e 2 estejam idênticos, será utilizado, como terceiro critério, pontos de execução 
            do  ponto extra: Preço visível (dentro do range), presença dos itens MUST STOCK LIST e MPDV.
        </p>

        <p>Exemplos:
        </p>

        <p>Exemplo 1
        </p>

        <p>Rede A: Atingiu 100% da meta de sell-in
        </p>

        <p>Chave de Entrada: 100% da meta = 100 pontos
        </p>

        <p>Pontuação: 0% acima da meta = 0 pontos
        </p>

        <p>Acelerador: Média de 1 ponto extra = 100 pontos
        </p>

        <p>Total de pontos = 200 pontos
        </p>

        <p>Rede B: Atingiu 110% da meta de sell-in
        </p>

        <p>Chave de Entrada: 100% da meta = 100 pontos
        </p>

        <p>Pontuação: 10% acima da meta = 100 pontos
        </p>

        <p>Acelerador: Sem ponto extra = 0 ponto
        </p>

        <p>Total de pontos = 200 pontos
        </p>

        <p>No exemplo 1, as duas redes A e B estavam em situação de empate, com 200 pontos, cada. Aplicando-se o 1º 
            critério de desempate (Sell In: quem tiver mais pontos percentuais acima da meta acordada) foi considerada 
            como vencedora a Rede B.
        </p>

        <br />

        <p>Exemplo 2:
        </p>

        <p>Rede A: Atingiu 102% da meta de sell-in
        </p>

        <p>Chave de Entrada: 100% da meta = 100 pontos
        </p>

        <p>Pontuação: 2% acima da meta = 20 pontos
        </p>

        <p>Acelerador: MÉDIA DE 2,5 PONTOs EXTRA = 250 pontos
        </p>

        <p>Ponto execução: PREÇO VISÍVEL
        </p>

        <p>Total de Pontos = 370 pontos
        </p>

        <br />

        <p>Rede B: Atingiu 102% da meta de sell-in
        </p>

        <p>Chave de Entrada: 100% da meta = 100 pontos
        </p>

        <p>Pontuação: 2% acima da meta = 20 pontos
        </p>

        <p>Acelerador: MÉDIA DE 2,5 PONTOs EXTRA = 250 pontos
        </p>

        <p>Ponto execução: SEM PREÇO VISÍVEL
        </p>

        <p>Total de Pontos = 370 pontos
        </p>

        <p>No exemplo 2, as duas redes A e B estavam empatadas com 370 pontos, cada. Aplicando-se os dois primeiros 
            critérios de desempate, a situação de empate se manteve, sendo necessário utilizar o terceiro critério 
            (pontos de execução do ponto extra: Preço Visível), que resultou como vencedora a Rede A.
        </p>

        <p>9.3. Ranking. Ao final de cada bimestre, a Promotora somará os pontos dos Participantes e serão premiados 
            àqueles que tiverem a maior pontuação
        </p>

        <p>9.3.1. Após a divulgação do ranking por Regional, os participantes terão o prazo de 10 (dez) dias para 
            contestação dos resultados junto à Promotora, findo esse período, os resultados serão considerados como 
            finais e os participantes serão premiados, conforme previsto em Regulamento.
        </p>

        <p>9.4. Concluída a apuração por bimestre, serão premiados os 265 melhores participantes que terão direito a 
            receber os prêmios descritos no item 7.
        </p>

        <h4>10 - CRITÉRIOS DE DESCLASSIFICAÇÃO:
        </h4>

        <p>10.1. Será desclassificado o participante que não observar corretamente as condições gerais de participação 
            e premiação previstas neste Regulamento.
        </p>

        <p>10.2. Os Participantes poderão ser excluídos automaticamente e a qualquer tempo da Campanha, em caso de 
            descumprimento deste Regulamento, bem como nos casos de suspeita de fraude e/ou prática de ato de má-fé, 
            respondendo civil e criminalmente pelos atos praticados.
        </p>

        <p> 10.3. A Promotora não será responsável pela autenticidade dos dados cadastrais e das demais informações 
            fornecidas pelos Participantes durante a execução da Campanha.
        </p>

        <p>10.4. Caso seja verificada qualquer divergência entre as informações prestadas na inscrição do Programa e 
            aquelas observadas nos documentos obtidos, o respectivo Participante será imediatamente desclassificado.
        </p>

        <p>10.5. Não ocorrendo o atendimento de quaisquer dos requisitos, termos e condições previstos neste 
            Regulamento, incluindo, mas não se limitando à hipótese de prestação de informações inverídicas, 
            incorretas, incompletas ou equivocadas, o Participante será automaticamente desclassificado do Programa.
        </p>

        <p>10.6. Havendo a desclassificação de algum Participante, por qualquer motivo, este perderá, automaticamente, 
            o direito aos pontos e/ou aos prêmios, independentemente de qualquer notificação.
        </p>

        <h4>11 - FORMA DE DIVULGAÇÃO DO RESULTADO:
        </h4>

        <p>O resultado de cada bimestre será divulgado através do site da URL: vitaminesuasvendascomtang.com.br e os 
            vencedores também serão contatados por e-mail e telefone (contato, via WhatsApp, no número de telefone 
            móvel informado no cadastro). A PROMOTORA tentará contato com o Participante em até 3 (três) tentativas e 
            no caso de a PROMOTORA não conseguir avisar o Participante após realizar o contato nos moldes aqui 
            estabelecidos o Participante será desclassificado. O Participante terá 30 (trinta) dias para aceitar o 
            prêmio. O não aceite pelo Participante ensejará sua desclassificação.
        </p>

        <h4>12 - ENTREGA DOS PRÊMIOS:
        </h4>

        <p>12.1. Comprovação de identidade. A Promotora considerará para entrega da premiação o representante legal da 
            pessoa jurídica cadastrada na Campanha que deverá comprovar a sua condição através da apresentação do 
            contrato social da empresa ou procuração pública, por exemplo, e cópias de seu RG, CPF.
        </p>

        <p>Os documentos deverão ser enviados, por e-mail, no prazo de 72 (setenta e duas) horas úteis do efetivo 
            contato, sob pena de perda do direito ao prêmio.
        </p>

        <p>Não sendo possível comprovar a condição ou no caso de divergência entre os dados cadastrais e o contrato 
            social, o ganhador será desclassificado e perderá o direito ao prêmio, devendo ser apurado outro ganhador, 
            de acordo com o Ranking Geral.
        </p>

        <p>12.2. O participante terá prazo máximo de 72 (setenta e duas) horas úteis, a partir do efetivo contato pela 
            empresa Promotora, para fornecer os documentos solicitados. Decorrido o prazo sem a apresentação dos 
            documentos ou os apresente de forma divergente do cadastro, o participante será desclassificado, 
            aplicando-se novamente a regra de prevista em Regulamento, até que se encontre um ganhador válido.
        </p>

        <p>12.3. Entrega do prêmio. Após as verificações previstas no item acima e no prazo máximo de 30 (trinta) dias, 
            contados das datas de entregas da documentação completa e válida pelos contemplados, de forma gratuita e 
            sem ônus, os cartões serão emitidos para o CNPJ do Participante ganhador e vinculado ao CPF do respectivo 
            representante legal e serão entregues, conforme informado nesse item.
        </p>

        <p>Cada ganhador deverá receber o cartão, ligar na empresa e efetuar seu cadastro e desbloqueio, após isso 
            entrar em contato com o SAC da campanha para informar os dados do cartão para então receber a carga com o 
            valor do prêmio equivalente.
        </p>

        <p>12.3.1. No momento da efetiva entrega do prêmio, o representante legal do Participante contemplado 
            obrigar-se-á a dar sua assinatura no recibo de entrega do prêmio e entregar cópias de seus documentos 
            pessoais (CPF e RG) e comprovante de residência, pelo qual dará ampla e irrestrita quitação a Promotora.
        </p>

        <p>12.3.2. O prêmio é individual e intransferível, bem como não poderá ser convertido, total ou parcialmente,   
            em dinheiro.
        </p>

        <p>12.3.3. Todas as despesas necessárias à entrega do prêmio, inclusive pagamento de taxas, impostos, 
            contribuições previdenciárias e/ou emolumentos serão custeados pela Promotora
        </p>

        <p>12.3.4. Nos termos do art. 70, inciso I, alínea b2, da Lei nº 11.196, de 26 de novembro de 2005, a empresa 
            Promotora recolherá 20% de IRRF sobre o valor dos prêmios, até o terceiro dia útil subsequente ao decêndio 
            de ocorrência do fato gerador
        </p>

        <h4>13 - DISPOSIÇÕES GERAIS:
        </h4>

        <p>13.1. Local de exibição do prêmio. Em razão de sua natureza, os prêmios não poderão ser exibidos. A 
            Promotora utilizará fotos meramente ilustrativas no site da Campanha.
        </p>

        <p>13.2. Comprovação dos prêmios. A Promotora comprovará a propriedade do prêmio pela juntada de cópias de 
            Notas Fiscais e/ou Fatura, em até 8 (oito) dias antes da data de apuração dos resultados.
        </p>

        <p>13.3. Canais e formas de divulgação institucional pela mídia. A divulgação da Campanha, para o conhecimento 
            de suas condições, pelos participantes, realizar-se-á por meio de material digital e vídeo enviados aos 
            Participantes e na URL: vitaminesuasvendascomtang.com.br.
        </p>

        <p>13.3.1. O regulamento completo estará disponível na URL: vitaminesuasvendascomtang.com.br.
        </p>

        <p>13.3.2. O número do Certificado de Autorização constará, de forma clara e precisa, na URL:
            vitaminesuasvendascomtang.com.br. Para os demais materiais de divulgação, a empresa Promotora solicita 
            dispensa da aposição, fazendo constar, apenas, a indicação de consulta do número de Autorização SEAE no 
            referido site.
        </p>

        <p>13.4. Esclarecimentos sobre a Campanha. As dúvidas dos participantes sobre a presente Campanha poderão ser 
            esclarecidas através do Canal Fale Conosco disponível na URL: vitaminesuasvendascomtang.com.br, horário de 
            atendimento de segunda à sexta-feira (exceto feriados), das 09h00 às 18h00.
        </p>

        <p>13.5. Comissão de julgamento. As dúvidas e controvérsias oriundas dos participantes da Promoção comercial 
            serão, primeiramente, dirimidas por seus respectivos organizadores, persistindo-as, estas serão submetidas 
            à SEAE/ME no silêncio injustificado, os participantes poderão a qualquer momento, durante o período da 
            Campanha, fazer reclamação, desde que fundamentadas, aos órgãos de defesa dos direitos do Participante de 
            sua cidade ou Estado.
        </p>

        <p>13.6. Autorização de uso de imagem. Os ganhadores desta Campanha autorizam, sem quaisquer ônus, a Promotora 
            a utilizar os seus dados pessoais, suas imagens, nomes e/ou vozes, em fotos, cartazes, filmes, bem como em 
            qualquer tipo de mídia e peças promocionais para a divulgação exclusiva da conquista do prêmio, no prazo de 
            até 01 (um) ano, a contar da data de apuração do contemplado.
        </p>

        <p>13.6.1. As autorizações descritas acima não implicam em qualquer obrigação de divulgação ou de pagamento de 
            qualquer quantia por parte da Promotora ao ganhador.
        </p>

        <p>13.6.2. À empresa regularmente autorizada nos termos da Lei 5.768/71, é deferida a formação de cadastro e/ou 
            banco de dados com as informações coletadas em promoção comercial, sendo expressamente vedada à 
            comercialização ou a cessão, ainda que a título gratuito, desses dados.
        </p>

        <p>13.7. Reconhecimento de cláusulas e aceitação do Regulamento. A simples participação na presente Campanha 
            implicará no total e integral reconhecimento das condições e aceitação irrestrita deste Regulamento, bem 
            como, presumir-se-á a condição de que o participante ganhador não possui impedimentos fiscal, legal ou 
            outro que o impeça de receber e/ou usufruir o prêmio ganho.
        </p>

        <p>13.8. Prestação de Contas. Após a entrega dos prêmios, a prestação de contas da promoção encerrada será 
            realizada pela procuradora nomeada pela Promotora e dentro do prazo estabelecido pela legislação.
        </p>

        <h4>14 - TERMO DE RESPONSABILIDADE
        </h4>

        <p>Poderá participar da promoção qualquer consumidor que preencha os requisitos estipulados no regulamento da 
            campanha autorizada;
        </p>

        <p>Os prêmios não poderão ser convertidos em dinheiro;
        </p>

        <p>É vedada a apuração por meio eletrônico;
        </p>

        <p>Os prêmios serão entregues em até 30 dias da data da apuração/sorteio, sem qualquer ônus aos contemplados
        </p>

        <p>Quando o prêmio sorteado, ganho em concurso ou conferido mediante vale-brinde, não for reclamado no prazo de 
            cento e oitenta (180) dias, contados, respectivamente, da data do sorteio, da apuração do resultado do 
            concurso ou do término do prazo da promoção, caducará o direito do respectivo titular e o valor 
            correspondente será recolhido, pela empresa autorizada, ao Tesouro Nacional, como renda da União, no prazo 
            de quarenta e cinco (45) dias;
        </p>

        <p>Em caso de promoções com participação de menor de idade, sendo este contemplado, deverá, no ato da entrega 
            do prêmio, ser representado por seu responsável legal; à exceção das promoções comerciais realizadas por 
            concessionária ou permissionária de serviço de radiodifusão, nos termos do artigo 1º-A, § 3º, da Lei 5.768, 
            de 20 de dezembro de 1971;
        </p>

        <p>A divulgação da imagem dos contemplados poderá ser feita até um ano após a apuração da promoção comercial;
        </p>

        <p>As dúvidas e controvérsias oriundas de reclamações dos participantes serão, primeiramente, dirimidas pela 
            promotora, persistindo-as, estas deverão ser submetidas à Secap/ME;
        </p>

        <p>Os órgãos locais de defesa do consumidor receberão as reclamações devidamente fundamentadas;
        </p>

        <p>A prestação de contas deverá ser realizada no prazo máximo de trinta dias após a data de prescrição dos 
            prêmios sob pena de descumprimento do plano de distribuição de prêmios;
        </p>

        <p>O regulamento deverá ser afixado em lugar de ampla visibilidade e se apresentar em tamanho e em grafia que 
            viabilizem a compreensão e visualização por parte do consumidor participante da promoção comercial;
        </p>

        <p>Além dos termos acima, a promoção comercial deverá obedecer às condições previstas na Lei nº 5.768, de 1971, 
            no Decreto nº 70.951, de 1972, Portaria MF nº 41, de 2008, Portaria MF nº 67, de 2017, Portaria MF nº 422 
            de 2013, Portaria Seae/MF nº 88 de 2000, e em atos que as complementarem.
        </p>

        <p>Para as modalidades "Sorteio" e "Assemelhada a Sorteio" a empresa deverá anexar a Lista de Participantes na 
            aba "Apurações", contendo nomes e números da sorte distribuídos, após o término de cada período de 
            participação e antes da extração da Loteria. O arquivo deverá ser no formato .csv, .xls ou .zip e cada 
            arquivo poderá ter até 250 MB.
        </p>

        <p>A infringência às cláusulas do Termo de Responsabilidade e do Regulamento constituem descumprimento do plano 
            de operação e ensejam as penalidade previstas no artigo 13 da Lei nº. 5.768, de 1971.
        </p>

      </div>
    </div>
  </Template>
}

export default Regulamento;
