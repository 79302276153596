import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { isAxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import defaultAvatar from '../../assets/img/logo-vitamine-com-tang.png'
import api from "../../services/api";

const AuthContext = createContext();

export default function AuthProvider ({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [permissions, setPermisssions] = useState([]);
  const [refreshToken, setRefreshToken] = useState(null);
  const [acceptedAt, setAcceptedAt] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const navigate = useNavigate()

  const userCan = useCallback((permission, checkApproved) => {
    if (!permissions) return false
    if (checkApproved && !user?.approved) return false;
    return permissions.includes(permission);
  }, [permissions, user])

  const doLogin = useCallback(async (login, password) => {
    try {
      const response = await api.post('session', { login, password });
      const { acceptedAt, refreshToken, token} = response.data;
      const userData = JSON.stringify({ ...response.data.user, acceptedAt: response.data.acceptedAt });

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', userData);
      localStorage.setItem('acceptedAt', acceptedAt);
      
      setToken(token);
      setRefreshToken(refreshToken);
      setUser(response.data.user);
      setAcceptedAt(acceptedAt)
      
      if (response.data.permissions) {
        const permissionsData = JSON.stringify(response.data.permissions);
        localStorage.setItem('permissions', permissionsData);
        setPermisssions(response.data.permissions || [])
      }

      setAvatar(defaultAvatar);
      if(response.data.user.avatar) {
        setAvatar(`${process.env.REACT_APP_IMG_URL}${response.data.user.avatar.path}`);
      }

      if (!acceptedAt) {
        navigate(`/activation/${response.data.user.activationToken}`);
        return;
      }
      if (token) {
          navigate('/home');
      }


      return response;
    } catch (err) {
      if (!isAxiosError(err)) {
        toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' })
        return
      }

      toast.error(err.response.data, { theme: 'colored' })
    }
  }, [navigate]);

  useEffect (() => {
    const tokenData = localStorage.getItem('token');
    if (!tokenData) return;

    const userData = JSON.parse(localStorage.getItem('user'));
    const acceptedAt = localStorage.getItem('acceptedAt');
    const permissions = localStorage.getItem('permissions');
    setToken(tokenData);
    setUser(userData);
    setAcceptedAt(acceptedAt);
    setPermisssions(permissions)
    if(userData.avatar) {
      setAvatar(`${process.env.REACT_APP_IMG_URL}${userData.avatar.path}`);
      return;
    }
    setAvatar(defaultAvatar);
  }, []);

  const doLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('catalogUrl');
    localStorage.removeItem('permissions');
    setToken(null);
    setUser(null);
    setAcceptedAt(null);
    setPermisssions(null);

    window.location = "/";

  }, []);

  useEffect(() => {
    if (!token) return;
    const timeout = setTimeout(async () => {
      try {
        const response = await api.post(`/session/refresh`, null, {
          headers: {
            'Authorization': `Bearer ${refreshToken}`
          }
        });
        const { token: newToken, refreshToken: newRefreshToken } = response.data;
        localStorage.setItem('token', newToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        setToken(newToken);
        setRefreshToken(newRefreshToken);
      } catch (error) {
        toast.error("O token expirou", { theme: "colored" });
        doLogout();
        return;
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(timeout);
  }, [token, refreshToken, doLogout])

  return (
    <AuthContext.Provider value={{user, token, acceptedAt, avatar, setAvatar, setUser, doLogin, doLogout, userCan}}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth () {
    const context = useContext(AuthContext)

    if (!context) throw new Error("useAuth must be used within a AuthProvider");

    const { user, token, avatar, acceptedAt, setAvatar, setUser, doLogin, doLogout, userCan } = context;

    return { user, token, avatar, acceptedAt, setAvatar, setUser, doLogin, doLogout, userCan };
}
