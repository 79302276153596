import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: .25rem;
  margin-bottom: 1rem;

  &>div {
    flex: 1 100%;
  }
`
