import { Fragment, useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import NoticeGroup from './NoticeGroup';
import * as S from "./style"
import { useClickOutside } from '../../hooks/useClickOutside';
import MobileMenu from './MobileMenu';
import { useAuth } from '../../contexts/AuthContext'
import logo from '../../assets/img/logo-vitamine-com-tang.png'

export default function Header() {
    const [activeSettings, setActiveSettings] = useState(false)
    const [wrapperRef] = useClickOutside(() => setActiveSettings(false));
    const location = useLocation();
    const { userCan, doLogout } = useAuth()


    const toggleSettings = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveSettings(!activeSettings);
    }, [activeSettings]);

    return (
        <Fragment>
            <header className="header">
                <MobileMenu />
                <nav className="header-actions">
                <div className="header-brand">
                    <div className="logo">
                        <img src={logo} alt="Logo da campanha" style={{maxWidth: '150px', marginTop: '80px'}} />
                    </div>
                </div>
                </nav>
                <nav className="header-actions navigation-widget-desktop">
                    <S.MenuItems className="menu">
                        <S.Li className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/home">
                                <div className="menu-item-link-icon">
                                    <p>Home</p>
                                </div>
                            </Link>
                        </S.Li>

                        {userCan('user-status:read') && <S.Li className={`menu-item ${location.pathname === '/admin/user-status' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/user-status">
                                <div className="menu-item-link-icon">
                                    <p>Status das Redes</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('photo:accept') && <S.Li className={`menu-item ${location.pathname === '/admin/photo-accept' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/photo-accept">
                                <div className="menu-item-link-icon">
                                    <p>Aceite de fotos</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('photo:approve') && <S.Li className={`menu-item ${location.pathname === '/admin/photo-approve' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/photo-approve">
                                <div className="menu-item-link-icon">
                                    <p>Aprovação de fotos</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('newsfeed:read') && <S.Li className={`menu-item ${location.pathname === '/perfil/newsfeed' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/perfil/newsfeed">
                                <div className="menu-item-link-icon">
                                    <p>Canal de notícias</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('providers:read') && <S.Li className={`menu-item ${location.pathname === '/providers' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/providers">
                                <div className="menu-item-link-icon">
                                    <p>Cadastro de Lojas</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('badges:read') && <S.Li className={`menu-item ${location.pathname === '/badges' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/badges" data-title="Medalhas">
                                <div className="menu-item-link-icon">
                                    <p>Badges</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('quests:read') && <S.Li className={`menu-item ${location.pathname === '/quests' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/quests" data-title="Missões">
                                <div className="menu-item-link-icon">
                                    <p>Quests</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('training:read') && <S.Li className={`menu-item ${location.pathname === '/treinamentos' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/treinamentos" data-title="Treinamentos">
                                <div className="menu-item-link-icon">
                                    <p>Treinamentos</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('forum:read') && <S.Li className={`menu-item ${location.pathname === '/forums' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/forums">
                                <div className="menu-item-link-icon">
                                    <p>Forums</p>
                                </div>
                            </Link>
                        </S.Li>}

                        {userCan('marketplace:read') && <S.Li className={`menu-item ${location.pathname === '/marketplace' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/marketplace" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Marketplace</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('notices:send') && <S.Li className={`menu-item ${location.pathname === '/admin/notice' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/notice" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Enviar Mensagens</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('users:approve') && <S.Li className={`menu-item ${location.pathname === '/admin/not-approved' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/not-approved" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Aguardando aprovação</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('ranking:read') && <S.Li className={`menu-item ${location.pathname === '/ranking' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/ranking" data-title="Resultados">
                                <div className="menu-item-link-icon">
                                    <p>Resultados</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('metas-all:read') && <S.Li className={`menu-item ${location.pathname === '/metas' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/metas" data-title="Resultados">
                                <div className="menu-item-link-icon">
                                    <p>Metas</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('ranking-all:read') && <S.Li className={`menu-item ${location.pathname === '/admin/ranking' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/ranking" data-title="Resultados">
                                <div className="menu-item-link-icon">
                                    <p>Resultados Staff</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('ranking-dist:read') && <S.Li className={`menu-item ${location.pathname === '/dist/ranking' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/dist/ranking" data-title="Resultados">
                                <div className="menu-item-link-icon">
                                    <p>Ranking</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('photo:read', true) && <S.Li className={`menu-item ${location.pathname === '/perfil/photos' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/perfil/photos" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Fotos - Pontos Extras</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('results:import') && <S.Li className={`menu-item ${location.pathname === '/admin/results' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/results" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Importar Resultados</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        {userCan('users:read') && <S.Li className={`menu-item ${location.pathname === '/admin/usuarios' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/usuarios" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Usuários</p>
                                </div>
                            </Link>
                        </S.Li>}
                        
                        <S.Li className={`menu-item ${location.pathname === '/downloads' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/downloads" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Material de Divulgação</p>
                                </div>
                            </Link>
                        </S.Li>
                        
                        <S.Li className={`menu-item ${location.pathname === '/mecanica' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/mecanica" data-title="Marketplace">
                                <div className="menu-item-link-icon">
                                    <p>Mecânica</p>
                                </div>
                            </Link>
                        </S.Li>
                        
                        {userCan('report:read') && <S.Li className={`menu-item ${location.pathname === '/admin/reports' ? 'active' : ''}`}>
                            <Link className="menu-item-link" to="/admin/reports" data-title="Relatórios">
                                <div className="menu-item-link-icon">
                                    <p>Relatórios</p>
                                </div>
                            </Link>
                        </S.Li>}

                    </S.MenuItems>
                </nav>

                <div className="header-actions">
                    {userCan('newsfeed:read') && <div className="action-list">
                        <NoticeGroup />
                    </div>}

                    <div className="action-item-wrap">
                        <div className="action-item dark header-settings-dropdown-trigger" onClick={toggleSettings} >
                            <svg className="action-item-icon icon-settings">
                                <use href="#svg-settings"></use>
                            </svg>
                        </div>

                        <S.Dropbox active={activeSettings} className="dropdown-navigation header-settings-dropdown" ref={wrapperRef}>

                            <Link className="dropdown-navigation-link" to="/perfil">Meu perfil</Link>
                            <Link className="dropdown-navigation-link" to="/faq">Perguntas frequentes</Link>
                            <Link className="dropdown-navigation-link" to="/regulamento">Regulamento</Link>
                            <Link className="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>
                            <Link className="dropdown-navigation-link" to="/terms">Termos de Uso</Link>


                            <S.LeaveButton
                                onClick={doLogout}
                                className="dropdown-navigation-button button small secondary"
                                type="button"
                            >
                                <i className="fas fa-door-closed"></i>
                                <span>Sair</span>
                            </S.LeaveButton>
                        </S.Dropbox>
                    </div>
                </div>

            </header>

        </Fragment>
    )
}