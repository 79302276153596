import styled, { keyframes } from 'styled-components'

const fadeEaseInOut = keyframes`
 0% {
    transform: translate(50%);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    position: absolute;
    left: 0px;
 }
 100% { 
    opacity: 1;
    transform: translateX(0px);
    visibility: visible;
    z-index: 1;
    position: absolute;
    left: 0px;
 }
`

export const ContainerLogin = styled.div`
   @media(min-width: 1365px) {
      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
`

export const ContainerRegister = styled.div`
   height: auto;
   max-height: auto;

   h2 {
      margin-bottom: 76px;
   }

   form {
      margin-top: 0 !important;
   }

   @media(min-width: 1365px) {   
      height: 100%;
      max-height: 735px;

      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
`

export const ContainerHolder = styled.div`
   width: 100%;
   height: 100%;
   overflow: auto;

   @media(min-width: 1366px) {
      width: 100%;
      height: calc(100% - 76px);
      overflow-y: auto;
      overflow-x: visible;
   }
`