import React from "react";
import ChatWidget from "../../components/ChatWidget";
import Template from "../../template";



export default function Chat() {
    return (
        <Template>
            <div className="account-hub-content">
                <div className="section-header">
                    <div className="section-header-info">
                        <h2 className="section-title">Mensagens/Chat</h2>
                    </div>
                </div>
                <div className="grid-column">
                    <ChatWidget />
                </div>
            </div>
        </Template>
    )
}