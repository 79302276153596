import React from "react";
import {
    Routes,
    Route,
    Navigate,
    Outlet
} from "react-router-dom";

// Pages
import Login from "./pages/Login";
import RecoveryPassword from "./pages/Login/Recovery";
import Activation from "./pages/Login/Activation";
import Newsfeed from "./pages/Newsfeed"
import Forums from "./pages/Forums";
import Overview from "./pages/Overview";
import Members from "./pages/Members";
import Badges from "./pages/Badges";
import Marketplace from "./pages/Marketplace";
import Product from "./pages/Marketplace/Product";
import Cart from "./pages/Marketplace/Product/Cart";
import Checkout from "./pages/Marketplace/Product/Cart/Checkout";
import Quests from "./pages/Quests";
import Treinamentos from "./pages/Treinamentos";
import Days100 from "./pages/Treinamentos/100days";
import AprendaAVender from "./pages/Treinamentos/AprendaAVender";
import Digital2021 from "./pages/Treinamentos/Digital2021";
import ConstrutorDeVendas from "./pages/Treinamentos/ConstrutorDeVendas";
import Perfil from "./pages/Perfil";
import Category from "./pages/Category";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Usuarios from "./pages/Usuarios";
import AddNewUser from "./pages/AddNewUser";
import EditUser from "./pages/EditUser";
import AdminNotice from "./pages/AdminNotice";
import AdminNoticeUser from "./pages/AdminNoticeUser";
import AdminUpload from "./pages/AdminUpload";
import NotApproved from "./pages/Admin/NotApprovedUsers";

import ProfileHome from "./pages/Perfil/tabs/ProfileHome";
import ProfileNewsfeed from "./pages/Perfil/tabs/ProfileNewsfeed";
import ProfileGroups from "./pages/Perfil/tabs/ProfileGroups";
import ProfilePhotos from "./pages/Perfil/tabs/ProfilePhotos";
import PhotoList from "./pages/Perfil/tabs/ProfilePhotos/PhotoList";
import ProfileMarketplace from "./pages/Perfil/tabs/ProfileMarketplace";
import ProfileBadges from "./pages/Perfil/tabs/ProfileBadges";
import ProfileStore from "./pages/Perfil/tabs/ProfileStore";
import ProfileBlogPosts from "./pages/Perfil/tabs/ProfileBlogPosts";
import ProfileEdit from "./pages/Perfil/tabs/ProfileEdit";
import FaleConosco from "./pages/FaleConosco";
import Ranking from "./pages/Ranking";
import RankingStaff from "./pages/RankingStaff";
import RankingDist from "./pages/RankingDist";
import RankingStaffHolding from "./pages/RankingStaffHolding";
import Providers from "./pages/Providers";
import NewProvider from "./pages/Providers/NewProvider";
import EditProvider from "./pages/Providers/EditProvider";
import Faq from "./pages/Faq";
import Regulamento from "./pages/Regulamento";
import UserTerms from "./pages/UserTerms";
import Downloads from "./pages/Downloads";
import Mecanica from "./pages/Mecanica";
import PhotoToApprove from "./pages/PhotoToApprove";
import PhotoToAccept from "./pages/PhotoToAccept";
import StatusUsuarios from "./pages/StatusUsuarios";
import Metas from "./pages/Metas";
import AdminReports from './pages/Admin/Reports'
import EmBreve from "./pages/EmBreve";
import { useAuth } from './contexts/AuthContext'
import Chat from "./pages/Chat";

function isAuthenticated() {
    const token = localStorage.getItem('token')
    if (!token) return false
    return true
}
  

function PrivateRoute({ permission, ...rest }) {
    const { userCan } = useAuth();
    if (!isAuthenticated()) return <Navigate to={{ pathname: '/' }} />
    if (!permission) return <Outlet/>
    if (userCan(permission)) return <Outlet/>
    return <Navigate to={{ pathname: '/' }} />
};

export default function Rotas() {
    return (<Routes>
            <Route element={<Login />} path="/" />
            <Route element={<Activation />} path="activation/:token" />
            <Route element={<RecoveryPassword />} path="/recovery-password/:token" />
            <Route element={<PrivateRoute />} >
                <Route element={<Home />} path="/home" />
                <Route element={<Newsfeed />} path="/newsfeed" />
                <Route element={<Forums />} path="/forums" />
                <Route element={<Overview />} path="/overview" />
                <Route element={<Members />} path="/members" />
                <Route element={<Badges />} path="/badges" />
                <Route element={<Quests />} path="/quests" />
                <Route element={<EmBreve />} path="/results" />
                <Route element={<Perfil />} path="/perfil" >
                    <Route index element={<ProfileHome />} />
                    <Route path="newsfeed" element={<ProfileNewsfeed />} />
                    <Route path="groups" element={<ProfileGroups />} />
                    <Route path="photos/" element={<ProfilePhotos />} />
                    <Route path="photos/:providerId" element={<PhotoList />} />
                    <Route path="marketplace" element={<ProfileMarketplace />} />
                    <Route path="blog-posts" element={<ProfileBlogPosts />} />
                    <Route path="badges" element={<ProfileBadges />} />
                    <Route path="store" element={<ProfileStore />} />
                    <Route path="edit" element={<ProfileEdit />} />
                </Route>
                <Route element={<Mecanica />} path="/mecanica" />
                <Route element={<FaleConosco />} path="/fale-conosco" />
                <Route element={<Providers />} path="/providers" />
                <Route element={<NewProvider />} path="/providers/new" />
                <Route element={<EditProvider />} path="/providers/:providerId" />
                <Route element={<Faq />} path="/faq" />
                <Route element={<Regulamento />} path="/regulamento" />
                <Route element={<UserTerms />} path="/terms" />
                <Route element={<Cart />} path="/cart" />
                <Route element={<Checkout />} path="/checkout" />
                <Route element={<Treinamentos />} path="/treinamentos" />
                <Route element={<Days100 />} path="/treinamentos/100-days" />
                <Route element={<AprendaAVender />} path="/treinamentos/aprenda-a-vender" />
                <Route element={<Digital2021 />} path="/treinamentos/digital-2021" />
                <Route element={<ConstrutorDeVendas />} path="/treinamentos/construtor-de-vendas" />
                <Route element={<Marketplace />} path="/marketplace" />
                <Route element={<Category />} path="/marketplace-category" />
                <Route element={<Product />} path="/product/:cod" />
                <Route element={<Downloads />} path="/downloads" />
                <Route element={<Ranking />} path="/ranking" />
                <Route element={<RankingDist />} path="/dist/ranking" />
                <Route element={<Metas />} path="/metas" />
                <Route element={<Chat />} path="/mensagens" />
                <Route permission="notices:send" element={<AdminNotice/>} exact path="/admin/notice" />
                <Route permission="users:read" element={<Usuarios />} path="/admin/usuarios" exact />
                <Route permission="users:create" element={<AddNewUser />} path="/admin/usuarios/novo" />
                <Route permission="users:update" element={<EditUser />} path="/admin/usuarios/:userId" />
                <Route permission="results:import" element={<AdminUpload type="results" />} path="/admin/results" />
                <Route permission="notices:send" element={<AdminNoticeUser />} path="/admin/notice/:userId" />
                <Route permission="users:approve" element={<NotApproved />} path="/admin/not-approved/" />
                <Route element={<AdminReports />} path="/admin/reports/" />
                <Route element={<PhotoToApprove />} path="/admin/photo-approve/" />
                <Route element={<PhotoToAccept />} path="/admin/photo-accept/" />
                <Route element={<RankingStaff />} path="/admin/ranking" />
                <Route element={<RankingStaffHolding />} path="/admin/ranking/:holdingId" />
                <Route permission="user-status:read" element={<StatusUsuarios />} path="/admin/user-status/" />

            </Route>
            <Route element={<NotFound />} path="*" />
        </Routes>
    );
}
