import { useCallback, useEffect, useState } from 'react'
import RankingImage from '../../Ranking/RankingImage'
import leaf from '../../../assets/img/folhas-sem-tang.png'
import api from '../../../services/api'
import * as S from './style'

const Row = ({rowData}) => {
  return <tr>
    <td style={{width: '10%'}}><RankingImage position={rowData.position} /></td>
    <td style={{fontSize: '1rem', padding: '2.5rem 0'}}>
      <S.HoldingName to={`/admin/ranking/${rowData.holdingId}`}>{rowData.name}</S.HoldingName>
    </td>
    <td style={{width: '5%', paddingLeft: "2rem"}}>
      <S.StarCell>
        <p>
          <small>Pontos</small>
          <br />
          {rowData.pontosTotais}
        </p>
        <S.Star src={leaf} alt="Estrela" />
      </S.StarCell>
    </td>
  </tr>
}


function RankingGeralStaff ({ onda, region, gr, ga, ex }) {
  const [ranking, setRanking] = useState()
  const loadData = useCallback (async (params) => {
    setRanking()
    const response = await api.get(`/ranking`, { params })
    setRanking(response.data.ranking)
  }, [])

  useEffect (() => {
    loadData({ stepId: onda, regionId: region.id, gr, ga, ex})
  }, [loadData, onda, region, gr, ga, ex])
  return <div className="widget-box-content">
    <S.Pergunta>
      <S.Widget>
        <h5 style={{width: '100%', marginTop: '32px'}}>Ranking Região {region.name}</h5>
        <S.Tabela>
          <table>
            {ranking && ranking.map(r => <Row rowData={r} />)}
          </table>
        </S.Tabela>
      </S.Widget>
    </S.Pergunta>
  </div>
}

export default RankingGeralStaff;
