import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 50px;
    @media(max-width: 48rem) {
        padding: 0.5rem;
    }
`;

export const Body = styled.div`
    width: 100%;
    padding: 5rem;
    border-radius: 12px;
    background-color: var(--white);
    box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
    position: relative;

    p {
        line-height: 1.5rem;
    }
    @media(max-width: 48rem) {
        padding: 0.5rem;
    }
`

export const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 16px;
`;
