import { useCallback } from 'react'
import Template from '../../../template'
import { useAuth } from '../../../contexts/AuthContext'
import api from '../../../services/api'
import * as S from './style'

export default function Reports (props) {
  const { userCan } = useAuth()
  const handleDownloadStatusRedeReport = useCallback(async () => {
    const response = await api.get('/user-status/export', {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'user-status.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadMetasReport = useCallback(async (stepId) => {
    const response = await api.get(`/metas/export`, {
      params: { stepId },
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'metas.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadRankingReport = useCallback(async () => {
    const response = await api.get(`/ranking/export`, {
      responseType: 'blob',
    })
    console.log(response)
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'ranking.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadRankingHomologReport = useCallback(async () => {
    const response = await api.get(`/ranking-homolog/export`, {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'ranking-homolog.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadResponsaveisReport = useCallback(async () => {
    const response = await api.get(`/responsaveis-legais/export`, {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'responsaveis-legais.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  const handleDownloadPhotoReport = useCallback(async (stepId) => {
    const response = await api.get('/photo-report', {
      responseType: 'blob',
    })
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'fotos.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, [])
  return (
    <>
    <Template>

      <div className="grid medium-space">
        <S.Grid>
          <S.GridFastAcccess>
            {userCan('user-status:export') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={handleDownloadStatusRedeReport}>
              <p className="product-category-box-title">Status das redes</p>
            </S.FastLink>}
            
            {userCan('metas-all:export') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={() => handleDownloadMetasReport(1)}>
              <p className="product-category-box-title">Metas etapa 1</p>
            </S.FastLink>}
            
            {userCan('metas-all:export') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={() => handleDownloadMetasReport(2)}>
              <p className="product-category-box-title">Metas etapa 2</p>
            </S.FastLink>}

            <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={handleDownloadPhotoReport}>
              <p className="product-category-box-title">Fotos</p>
            </S.FastLink>

            {userCan('ranking-all:export') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={handleDownloadRankingReport}>
              <p className="product-category-box-title">Ranking Ativo</p>
            </S.FastLink>}

            {userCan('ranking-preview-all:export') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={handleDownloadRankingHomologReport}>
              <p className="product-category-box-title">Ranking em Homologação</p>
            </S.FastLink>}

            {userCan('responsaveis-legais:read') && <S.FastLink className="product-category-box category-all" style={{background: `linear-gradient(90deg,var(--primary),var(--secondary))`}} onClick={handleDownloadResponsaveisReport}>
              <p className="product-category-box-title">Responsaveis legais</p>
            </S.FastLink>}
          </S.GridFastAcccess>
        </S.Grid>
      </div>

    </Template >
  </>
  )
}