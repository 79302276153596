import { useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useCampaign } from '../../../contexts/CampaignContext';
import { useClickOutside } from '../../../hooks/useClickOutside';
import HexagonComponent from '../../Hexagon'
import * as S from './style';

function MobileMenu() {
    const [openMenu, setOpenMenu] = useState(false)
    const { campaignData } = useCampaign()
    const [wrapperRef] = useClickOutside(function () { setOpenMenu(false) });
    const location = useLocation();
    const { userCan } = useAuth()
    const { avatar, name } = JSON.parse(localStorage.getItem('user'));
    const selectedAvatar = avatar ? avatar : campaignData?.defaultAvatar;
    const navigate = useNavigate();

    function handleLogout() {
        localStorage.clear();
        navigate("/")
    }

    const toggleMenu = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenMenu(!openMenu);
    }, [openMenu]);

    return (
        <div className="header-actions">
            <S.MobileWrapper className="action-list" onClick={toggleMenu}>
                <div className="action-list-item-wrap">
                    <div className="action-list-item">
                        <div className="action-list-item-icon burger-icon inverted">
                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>
                        </div>
                    </div>
                </div>
                <div  >
                    <nav id="navigation-widget-mobile" className={`navigation-widget navigation-widget-mobile sidebar left ${openMenu ? '' : 'hidden'}`} >
                        <div className="simplebar-wrapper" style={{ margin: "0px 0px -40px" }}>

                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer"></div>
                            </div>

                            <div className="simplebar-mask" onClick={toggleMenu} ref={wrapperRef}>
                                <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                    <div className="simplebar-content-wrapper">
                                        <div className="simplebar-content" style={{ padding: "0px 0px 40px" }}>

                                            <div className="navigation-widget-close-button" onClick={toggleMenu}>
                                                <svg className="navigation-widget-close-button-icon icon-back-arrow">
                                                    <use href="#svg-back-arrow"></use>
                                                </svg>
                                            </div>

                                            <div className="navigation-widget-info-wrap">
                                                <div className="navigation-widget-info">
                                                    <Link className="user-avatar small no-outline" to="/perfil">
                                                        <div className="user-avatar-content">
                                                            <div className="hexagon-container">
                                                                <HexagonComponent src={selectedAvatar} alt="Avatar" />
                                                            </div>
                                                        </div>
                                                    </Link>

                                                    <p className="navigation-widget-info-title"><Link to="/perfil">{name}</Link></p>

                                                    <p className="navigation-widget-info-text">Bem vindo!</p>
                                                </div>

                                                <button
                                                    type="text"
                                                    onClick={handleLogout}
                                                    className="navigation-widget-info-button button small secondary"
                                                >
                                                    Sair
                                                </button>
                                            </div>

                                            <p className="navigation-widget-section-title">Seções</p>

                                            <ul className="menu">

                                                <S.Li className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/home">
                                                        <div className="menu-item-link-icon">
                                                            <p>Home</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>

                                                {userCan('user-status:read') && <S.Li className={`menu-item ${location.pathname === '/admin/user-status' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/user-status">
                                                        <div className="menu-item-link-icon">
                                                            <p>Status das Redes</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('photo:accept') && <S.Li className={`menu-item ${location.pathname === '/admin/photo-accept' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/photo-accept">
                                                        <div className="menu-item-link-icon">
                                                            <p>Aceite de fotos</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('photo:approve') && <S.Li className={`menu-item ${location.pathname === '/admin/photo-approve' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/photo-approve">
                                                        <div className="menu-item-link-icon">
                                                            <p>Aprovação de fotos</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('newsfeed:read') && <S.Li className={`menu-item ${location.pathname === '/perfil/newsfeed' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/perfil/newsfeed">
                                                        <div className="menu-item-link-icon">
                                                            <p>Canal de notícias</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('users:read') && <S.Li className={`menu-item ${location.pathname === '/members' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/members">
                                                        <div className="menu-item-link-icon">
                                                            <p>Membros</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('providers:read') && <S.Li className={`menu-item ${location.pathname === '/providers' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/providers">
                                                        <div className="menu-item-link-icon">
                                                            <p>Cadastro de Lojas</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('badges:read') && <S.Li className={`menu-item ${location.pathname === '/badges' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/badges" data-title="Medalhas">
                                                        <div className="menu-item-link-icon">
                                                            <p>Badges</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('quests:read') && <S.Li className={`menu-item ${location.pathname === '/quests' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/quests" data-title="Missões">
                                                        <div className="menu-item-link-icon">
                                                            <p>Quests</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('training:read') && <S.Li className={`menu-item ${location.pathname === '/treinamentos' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/treinamentos" data-title="Treinamentos">
                                                        <div className="menu-item-link-icon">
                                                            <p>Treinamentos</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('forum:read') && <S.Li className={`menu-item ${location.pathname === '/forums' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/forums">
                                                        <div className="menu-item-link-icon">
                                                            <p>Forums</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}

                                                {userCan('marketplace:read') && <S.Li className={`menu-item ${location.pathname === '/marketplace' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/marketplace" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Marketplace</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('notices:send') && <S.Li className={`menu-item ${location.pathname === '/admin/notice' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/notice" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Enviar Mensagens</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('users:approve') && <S.Li className={`menu-item ${location.pathname === '/admin/not-approved' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/not-approved" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Aguardando aprovação</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                        
                                                {userCan('ranking:read') && <S.Li className={`menu-item ${location.pathname === '/ranking' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/ranking" data-title="Resultados">
                                                        <div className="menu-item-link-icon">
                                                            <p>Resultados</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('metas-all:read') && <S.Li className={`menu-item ${location.pathname === '/metas' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/metas" data-title="Resultados">
                                                        <div className="menu-item-link-icon">
                                                            <p>Metas</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('ranking-all:read') && <S.Li className={`menu-item ${location.pathname === '/admin/ranking' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/ranking" data-title="Resultados">
                                                        <div className="menu-item-link-icon">
                                                            <p>Resultados Staff</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('photo:read', true) && <S.Li className={`menu-item ${location.pathname === '/perfil/photos' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/perfil/photos" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Fotos - Pontos Extras</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('results:import') && <S.Li className={`menu-item ${location.pathname === '/admin/results' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/results" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Importar Resultados</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('ranking:import') && <S.Li className={`menu-item ${location.pathname === '/admin/ranking' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/ranking" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Importar Ranking</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                {userCan('users:read') && <S.Li className={`menu-item ${location.pathname === '/admin/usuarios' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/admin/usuarios" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Usuários</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>}
                                                
                                                <S.Li className={`menu-item ${location.pathname === '/downloads' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/downloads" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Material de Divulgação</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>
                                                
                                                <S.Li className={`menu-item ${location.pathname === '/mecanica' ? 'active' : ''}`}>
                                                    <Link className="menu-item-link" to="/mecanica" data-title="Marketplace">
                                                        <div className="menu-item-link-icon">
                                                            <p>Mecânica</p>
                                                        </div>
                                                    </Link>
                                                </S.Li>
                                            </ul>

                                            <p className="navigation-widget-section-title">Meu perfil</p>

                                            <Link className="navigation-widget-section-link" to="/perfil">Perfil</Link>

                                            <Link className="navigation-widget-section-link" to="/perfil/newsfeed">Notificações</Link>


                                            <p className="navigation-widget-section-title">Conta</p>


                                            <Link className="navigation-widget-section-link" to="/perfil/edit">Configurações Gerais</Link>

                                            <p className="navigation-widget-section-title">Minha loja</p>

                                            <Link className="navigation-widget-section-link" to="/perfil">
                                                Minha Conta
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" style={{ width: "auto", height: "270px" }}></div>
                        </div>
                        <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
                            <div className="simplebar-scrollbar" style={{ height: "33px", transform: "translate3d(0px, 0px, 0px)", display: "block" }}></div>
                        </div>
                    </nav>
                </div>
            </S.MobileWrapper>
        </div>
    )
}

export default MobileMenu;