import styled from "styled-components";

export const Video = styled.video`
  max-width: 400px;
  max-height: 500px;
`

export const Image = styled.img`
  max-width: 400px;
  max-height: 500px;
`

export const IconImage = styled.img`
  max-width: 200px;
  max-height: 200px;
`

export const Stick = styled.img`
  max-width: 200px;
  max-height: 200px;
`

export const Audio = styled.audio`
  width: 100%;
`