import { useCallback, useEffect, useRef } from "react";
import { useMessages } from "../../../contexts/useMessages";
import pdfIcon from '../../../assets/img/pdf.png'
import * as S from './styled'

const imageFormats = ['image/jpeg']
const stickFormats = ['image/webp']
const audioFormats = ['audio/ogg; codecs=opus']

function Messages() {
  const { conversation } = useMessages();
  const messagesContainer = useRef();
  const displayContent = useCallback ((content) => {
    switch (content.type) {
      case "text":
        return content.text
      case "file":
        if (imageFormats.includes(content.fileMimeType)) {
          return <a href={content.fileUrl} target="_blank" rel="noreferrer">
            <S.Image src={content.fileUrl} alt={content.caption} />
          </a>
        }
        if (stickFormats.includes(content.fileMimeType)) {
          return <a href={content.fileUrl} target="_blank" rel="noreferrer">
            <S.Stick src={content.fileUrl} alt={content.caption} />
          </a>
        }
        if (audioFormats.includes(content.fileMimeType)) {
          return <S.Audio src={content.fileUrl} autoplay={false} controls={true}>
              O seu navegador não suporta o elemento <code>audio</code>.
            </S.Audio>
        }
        if (content.fileMimeType === 'video/mp4') {
          return <S.Video src={content.fileUrl} controls />        
        }
        if (content.fileMimeType === 'application/pdf') {
          return <a href={content.fileUrl} target="_blank" rel="noreferrer">
            <S.IconImage src={pdfIcon} alt={content.caption} />
          </a>
        }
        return `[FORMADO NÃO IDENTIFICADO] ${content.fileUrl}`
      case "template":
        return 'Olá, você entrou em contato com Vitamine suas vendas! No que posso ajudar?'
      default:
        console.log(content)
        return '[VALOR NÃO IDENTIFICADO]'
    }
  }, [])
  const ConversationComponent = ({ conversationItem }) => (
    <div className={`chat-widget-speaker ${conversationItem?.direction === "IN" ? "left" : "right"}`}>
      <p className="chat-widget-speaker-message">{displayContent(conversationItem)}</p>
      <p className="chat-widget-speaker-timestamp">{}{new Date(conversationItem.timestamp || conversationItem.createdAt).toLocaleString('pt-BR')}</p>
    </div>
  )

  useEffect(() => {
    let timeout;
    if (messagesContainer?.current) {
      timeout = setTimeout(() => {
        messagesContainer?.current.scrollTo(0, messagesContainer?.current.scrollHeight)
      }, 500)
    }
    
    return () => {
      if (!timeout) return
      clearTimeout(timeout)
    }
  }, [conversation])

  return (
    <div ref={messagesContainer} className="chat-widget-conversation" data-simplebar="init">
      <div className="simplebar-wrapper">
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer"></div>
        </div>
        <div className="simplebar-mask">
          <div className="simplebar-offset">
            <div className="simplebar-content-wrapper">
              <div className="simplebar-content">
                {conversation?.length > 0 ? conversation.map((conversationItem) => <ConversationComponent key={conversationItem?.id} conversationItem={conversationItem} />)
                  : <div>Não há mensagens ou contato selecionado</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="simplebar-placeholder">
        </div>
      </div>
      <div className="simplebar-track simplebar-horizontal">
        <div className="simplebar-scrollbar">
        </div>
      </div>
      <div className="simplebar-track simplebar-vertical">
        <div className="simplebar-scrollbar">
        </div>
      </div>
    </div>
  )
}

export default Messages;