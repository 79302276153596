import React, { useState } from "react";
import { Link } from "react-router-dom"

// Template
import Template from "../../template"

// Component
import BannerTitleComponent from "../../components/BannerTitle"
//import PageLoaderComponent from "../../components/PageLoader"
import Filter from "../../components/Filter"

// Image
import BannerTitle from "../../assets/img/banner/members-icon.png"
import Membros from "../../assets/img/cover/04.jpg"
import Img01 from "../../assets/img/badge/silver-s.png"
import Img02 from "../../assets/img/badge/fcultivator-s.png"
import Img03 from "../../assets/img/badge/scientist-s.png"
import Img04 from "../../assets/img/badge/rmachine-s.png"
import Img05 from "../../assets/img/badge/blank-s.png"

// Style
import { ImgPreview } from "./style"

export default function Quests () {
    const [search, setSearch] = useState('')
    const [, setFilter] = useState('')

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Membros (256)"
                subTitle="Todos os membros da comunidade!"
                imgAlt="member-icon"
            />

            <Filter onChange={setSearch} onSubmit={() => setFilter(search.value)} label="Buscar amigos">
                <div className="filter-tabs">
                    <div className="filter-tab active">
                        <p className="filter-tab-text">Ativos</p>
                    </div>

                    <div className="filter-tab">
                        <p className="filter-tab-text">Novos usuários</p>
                    </div>

                    <div className="filter-tab">
                        <p className="filter-tab-text">Alfabético</p>
                    </div>
                </div>
            </Filter>

            <div className="grid grid-4-4-4 centered">
                <div className="user-preview">
                    <figure className="user-preview-cover liquid" style={{ background: "rgba(0, 0, 0, 0) url(&quot;../../assets/img/cover/04.jpg&quot;) no-repeat scroll center center / cover" }}>
                        <ImgPreview src={Membros} alt="cover-04" />
                    </figure>

                    <div className="user-preview-info">
                        <div className="user-short-description">
                            <Link className="user-short-description-avatar user-avatar medium" to="/profile-timeline">
                                <div className="user-avatar-border">
                                    <div className="hexagon-120-132" style={{ width: "120px", height: "132px", position: "relative" }}>
                                        {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="120" height="132"></canvas>*/}
                                    </div>
                                </div>
                            </Link>

                            <p className="user-short-description-title">
                                <Link to="/profile-timeline">Daniel Galvão</Link>
                            </p>

                        </div>

                        <div className="badge-list small">
                            <div className="badge-item">
                                <img src={Img01} alt="badge-silver-s" />
                            </div>

                            <div className="badge-item">
                                <img src={Img02} alt="badge-fcultivator-s" />
                            </div>

                            <div className="badge-item">
                                <img src={Img03} alt="badge-scientist-s" />
                            </div>

                            <div className="badge-item">
                                <img src={Img04} alt="badge-rmachine-s" />
                            </div>

                            <Link className="badge-item" to="/profile-badges">
                                <img src={Img05} alt="badge-blank-s" />
                                <p className="badge-item-text">+29</p>
                            </Link>
                        </div>

                        <div className="tns-outer" id="user-preview-stats-slides-01-ow">
                            <div className="tns-liveregion tns-visually-hidden" aria-live="polite"></div>
                            <div id="user-preview-stats-slides-01-mw" className="tns-ovh">
                                <div className="tns-inner" id="user-preview-stats-slides-01-iw">
                                    <div id="user-preview-stats-slides-01" className="user-preview-stats-slides  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal">
                                        <div className="user-preview-stats-slide tns-item tns-slide-active" id="user-preview-stats-slides-01-item0">
                                            <div className="user-stats">
                                                <div className="user-stat">
                                                    <p className="user-stat-title">874</p>

                                                    <p className="user-stat-text">posts</p>
                                                </div>

                                                <div className="user-stat">
                                                    <p className="user-stat-title">60</p>

                                                    <p className="user-stat-text">amigos</p>
                                                </div>

                                                <div className="user-stat">
                                                    <p className="user-stat-title">3.9k</p>

                                                    <p className="user-stat-text">visitas</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="user-preview-stats-slide tns-item" id="user-preview-stats-slides-01-item1" aria-hidden="true" tabindex="-1">
                                            <p className="user-preview-text">
                                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id, quae cupiditate
                                                temporibus molestiae ut, sapiente vitae obcaecati nemo natus tempora quasi vel
                                                perspiciatis accusamus veritatis sit, enim quaerat culpa delectus.
                                            </p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div id="user-preview-stats-roster-01" className="user-preview-stats-roster slider-roster" aria-label="Carousel Pagination">
                            <div className="slider-roster-item tns-nav-active" data-nav="0" aria-label="Carousel Page 1 (Current Slide)" aria-controls="user-preview-stats-slides-01"></div>

                            <div className="slider-roster-item" data-nav="1" tabindex="-1" aria-label="Carousel Page 2" aria-controls="user-preview-stats-slides-01"></div>
                        </div>

                        <div className="user-preview-actions">
                            <p className="button secondary">Adicionar amigo +</p>

                            <p className="button primary">Enviar Mensagem</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-pager-bar">
                <div className="section-pager">
                    <div className="section-pager-item active">
                        <p className="section-pager-item-text">01</p>
                    </div>
                </div>

                <div className="section-pager-controls">
                    <div className="slider-control left disabled">
                        <svg className="slider-control-icon icon-small-arrow">
                            <use href="#svg-small-arrow"></use>
                        </svg>
                    </div>

                    <div className="slider-control right">
                        <svg className="slider-control-icon icon-small-arrow">
                            <use href="#svg-small-arrow"></use>
                        </svg>
                    </div>
                </div>
            </div>

            <p className="section-results-text">Mostrar 1 de 1 membros</p>
        </Template>
    )
}