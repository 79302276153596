import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { staticCampaignData } from "./static";

const CampaignContext = createContext();

export default function CampaignProvider ({ children }) {
  const [campaignData, setCampaignData] = useState({ });

  useEffect(() => {
    const loadCampaignData = async () => {
      // We could set a API request to data right here
      setTimeout(() => {
        setCampaignData(staticCampaignData)
      }, 1000);
  }
  loadCampaignData();
}, []);
  
  return (
    <CampaignContext.Provider value={{campaignData}}>
      {children}
    </CampaignContext.Provider>
  );
};

export function useCampaign() {
    const context = useContext(CampaignContext)

    if (!context) throw new Error("useAuth must be used within a AuthProvider");

    const { campaignData } = context;

    return { campaignData };
}
