import { useCallback, useEffect, useState } from "react"
import InputSelect from "../../../../components/Forms/InputSelect"
import api from "../../../../services/api"

export default function SeletorGR ({ regional, onChange }) {
  const [gr, setGr] = useState()
  const loadGr = useCallback(async (params) => {
    setGr()
    const response = await api.get(`/gr`, { params })
    setGr(response.data.gr)
  }, [])
  useEffect(() => {
    if (!regional) return
    if (!regional.id) return
    const params = { regionId: regional.id }
    loadGr(params)
  }, [loadGr, regional])
  return <InputSelect label="Selecione um GR" onChange={(evt) => onChange(evt)} large={true}>
    <option value="">Selecione um GR</option>
    {gr && gr.map(r => <option value={r.name}>{r.name}</option>)}
  </InputSelect>
}