import axios from 'axios'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { io } from 'socket.io-client'

const socket = io(String(`${process.env.REACT_APP_WPP_URL}`))

const MessagesContext = createContext(null)

export default function MessagesProvider({ children }) {
  const [contacts, setContacts] = useState([])
  const [activeContact, setActiveContact] = useState()
  const [conversation, setConversation] = useState([])

  const openConversation = useCallback(async (contactId) => {
    const conversation = await axios.post(`${process.env.REACT_APP_WPP_URL}conversations`, { contactId })
    setConversation(conversation.data)
  }, [])

  const selectContact = useCallback((contactId, contacts) => {
    setContacts(contacts => {
      const selectedIndex = contacts.findIndex((oldContact) => oldContact.id === contactId)
      contacts[selectedIndex].newMessageCount = null
      return contacts
    })
    setActiveContact(contacts.find(c => c.id === contactId))
    openConversation(contactId)
  }, [openConversation])

  const loadContacts = useCallback(async () => {
    const contacts = await axios.post(`${process.env.REACT_APP_WPP_URL}contacts`)
    setContacts(contacts.data)
    selectContact(contacts.data[0].id, contacts.data)
    openConversation(contacts.data[0].id)
  }, [openConversation, selectContact])

  const addMessage = useCallback((message) => {
    setConversation((c) => [...c, message])
  }, [])

  useEffect(() => {
    loadContacts()
  }, [loadContacts])

  useEffect(() => {
    socket.on('message', (data) => {
      const contact = data.contact

      setContacts(contacts => {
        if (activeContact?.id !== contact.id) {
          const messagedContact = contacts.find((oldContact) => oldContact.id === contact.id)
          contact.newMessageCount = messagedContact?.newMessageCount ? messagedContact.newMessageCount + 1 : 1
        }
        return [contact, ...contacts.filter(oldContact => oldContact.id !== contact.id)]
      })
      if (!activeContact) return
      if (activeContact.id !== contact.id) return
      setConversation((c) => [...c, data.message])
    })

    return () => {
      socket.off('message')
    }
  }, [activeContact])

  return <MessagesContext.Provider value={{ contacts, activeContact, conversation, openConversation, selectContact, addMessage }}>
    {children}
  </MessagesContext.Provider>
}

export const useMessages = () => {
  const context = useContext(MessagesContext)
  if (!context) throw new Error('useMessages must be used within a MessagesProvider')
  return context
}
