import Template from "../../template"
import BannerTitleComponent from "../../components/BannerTitle"
import BannerTitle from "../../assets/img/banner/quests-icon.png"
import Banner from '../Downloads/Banner'
import * as S from './style'

function Mecanicas () {
  return (
    <>
    <Template>
    <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Mecanica"
        subTitle="Veja a mecânica da campanha!"
        imgAlt="quests-icon"
    />

      <S.MecanicaWidgetBox>
        <S.PDFEmbed src="/downloads/CERTIFICADO DE AUTORIZAÇÃO SEAE_ME Nº 03.022242_2022_Campanha Incentivo Varejistas TANG_Regulamento_0202205526-6.pdf" />
      </S.MecanicaWidgetBox>

      <S.Grid>
        <Banner text="Certificado de Autorização" link="/downloads/CERTIFICADO DE AUTORIZAÇÃO SEAE_ME Nº 03.022242_2022_Campanha Incentivo Varejistas TANG_Regulamento_0202205526-6.pdf" download="CERTIFICADO DE AUTORIZAÇÃO SEAE_ME Nº 03.022242_2022_Campanha Incentivo Varejistas TANG_Regulamento_0202205526-6.pdf" />
      </S.Grid>

    </Template >
  </>
  )
}

export default Mecanicas