import React, { useCallback, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import * as S from './style';
import Template from "../../template"
import BannerTitleComponent from "../../components/BannerTitle"
import PaginationComponent from "../../components/Pagination"
import InputTextWithButton from "../../components/Forms/InputTextWithButton"
import LinkButton from "../../components/Forms/LinkButton"
import BannerTitle from "../../assets/img/banner/accounthub-icon.png"
import api from "../../services/api";
import PageLoaderComponent from "../../components/PageLoader";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext'
import { useModal } from "../../contexts/ModalContext";

export default function Usuarios () {
    const navigate = useNavigate();

    const [users, setUsers] = useState()
    const [filter, setFilter] = useState("")
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const { userCan } = useAuth()
    const { openModal } = useModal()

    const loadUsers = useCallback(async (page) => {
      function handleLogout () {
        localStorage.clear();
        navigate("/")
      }  
      try {
        const response = await api.get(`/user/search?page=${page}&filter=${filter}`)
        setUsers (response.data.users)
        setTotalPages(response.data.totalPages)
        setCurrentPage(response.data.currentPage)
      } catch (err) {
        if (!err.response.data.message) {
          toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: "colored" });
          return;
        }
  
        if (err.response.data.message === 'Token inválido') {
          handleLogout();
          toast.error(err.response.data.message, { theme: "colored" });
          return;
        }
  
        toast.error(err.response.data.message, { theme: "colored" });
      }
    },[filter, navigate])

    const confirmExclude = useCallback(async (userId) => {
      function handleLogout () {
        localStorage.clear();
        navigate("/")
      }  
      try {
        await api.delete(`/user/${userId}`)
        const userIndex = users.findIndex(user => user.id === userId);
        setUsers ((users) => {
          users.splice(userIndex, 1)
          return [...users]
        })
        toast.success('O usuário foi excluido com sucesso', { theme: "colored" });
      } catch (err) {
        if (!err.response.data.message) {
          toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: "colored" });
          return;
        }
  
        if (err.response.data.message === 'Token inválido') {
          handleLogout();
          toast.error(err.response.data.message, { theme: "colored" });
          return;
        }
  
        toast.error(err.response.data.message, { theme: "colored" });
      }
    }, [navigate, users])

    const handleExclude = useCallback((userId) => {
      const user = users.find(user => user.id === userId)
      openModal({
        title: "ATENÇÃO",
        body: (<div className="form-row"><p>Essa ação é irreversivel.<br />Você quer mesmo excluir o usuário "{user.name}"?</p><button className="button primary" type="button" onClick={() => confirmExclude(userId)}>Confirmar</button></div>),
      })
    }, [users, openModal, confirmExclude])

    const handleFilter = useCallback(async (newFilter) => {
      setFilter(newFilter || "")
      loadUsers(1)
    }, [loadUsers]);

    const handleResetPass = useCallback(async (userId) => {
      await api.patch(`/user/${userId}/clear-password`)
      
      openModal({
        title: "ATENÇÃO",
        body: <div className="form-row">A senha foi resetada com sucesso!</div>,
      })
    }, [openModal])

    const handleAuthAs = useCallback(async (userId) => {
      const response = await api.post(`/auth-as-user`, { userId })
      console.log(response)

      const { token, refreshToken, user, acceptedAt } = response.data
      
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('acceptedAt', acceptedAt);

      if (response.data.permissions) {
        const permissionsData = JSON.stringify(response.data.permissions)
        localStorage.setItem('permissions', permissionsData)
      }

      window.location = '/home'
    }, [])

    const downloadPhotoReport = useCallback(async () => {
      const response = await api({
        url: '/photo-report',
        method: 'GET',
        responseType: 'blob',
    })
        const href = URL.createObjectURL(response.data);
    
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'fotos-relatorio.xlsx');
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }, [])

    useEffect(() => {
      loadUsers(1)
    },[loadUsers])

    if (!users) return <PageLoaderComponent />

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Usuários"
                subTitle="Veja todos os usuários cadastrados"
                imgAlt="newsfeed-icon"
            />

            <S.Tabela>
              <S.Wrapper className="grid mobile-prefer-content">
                <S.HeaderWrapper>
                  <InputTextWithButton label="Matricula ou Email" onClick={(value) => handleFilter(value)} />

                  <LinkButton to="/admin/usuarios/novo" >Adicionar novo</LinkButton>
                  <button onClick={downloadPhotoReport} className="button secondary" style={{width: '300px', marginLeft: '16px'}}>Relatório de fotos</button>
                </S.HeaderWrapper>
                <div className="grid-column">
                  <table>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>CPF</th>
                      <th>&nbsp;</th>
                    </tr>
                    {users.map(user => <tr>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.document}</td>
                      <S.ActionContainer>
                        {userCan('users:login-as') && <S.Premmiar type="button" onClick={() => handleAuthAs(user.id)}>
                          <i class="fa-solid fa-right-to-bracket"></i>
                        </S.Premmiar>}
                        {userCan('users:update') && <S.Premmiar type="button" onClick={() => handleResetPass(user.id)}>
                          <i class="fas fa-key"></i>
                        </S.Premmiar>}
                        {userCan('users:update') && <S.Edit to={`/admin/usuarios/${user.id}`}>
                          <i class="fas fa-edit"></i>
                        </S.Edit>}
                        {userCan('users:delete') && <S.Exclude type="button" onClick={() => handleExclude(user.id)}>
                          <i class="fas fa-trash-alt"></i>
                        </S.Exclude>}
                      </S.ActionContainer>
                    </tr>)}
                  </table>
                </div>
              </S.Wrapper>
            </S.Tabela>
            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onChagePage={(page)=> loadUsers(page)} />
        </Template >
    )
}