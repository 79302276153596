import React from "react";
import { Link } from "react-router-dom"

export default function NotFound() {
    return (
        <div className="error-section">
            <p className="error-section-title">404</p>

            <div className="error-section-info">

                <p className="error-section-subtitle">Oops!!...</p>

                <p className="error-section-text">Caso o erro continue, por favor, fale com nosso suporte
                    <Link to="/forums">&nbsp;support@casadebraganca.com</Link>
                </p>

                <Link className="button medium primary" to="/quests">Voltar ao início</Link>
            </div>
        </div>
    )
}