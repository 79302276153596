import styled from "styled-components";

export const PageLoaderContainer = styled.div`
  img {
    max-width: 15rem;
    margin-right: 3rem;
  }
  .page-loader-info {
    margin-top: 3rem;
  }
`