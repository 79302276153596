import { useCallback } from 'react'
import ErrorComponent from '../ErrorComponent'
import * as S from './style'

export default function InputSelect({ name, id, label, value, onChange, children, large, error, disabled }) {
  const handleChange = useCallback((evt) => {
    if(onChange) onChange(evt.target.value)
  }, [onChange]);

  return <S.FormSelect>
    <label for={id}>{label}</label>
    <select id={id} name={name} onChange={handleChange} disabled={disabled}>
      {children}
    </select>
    <S.FormSelectIcon>
      <use href="#svg-small-arrow"></use>
    </S.FormSelectIcon>
    <ErrorComponent>{error}</ErrorComponent>
  </S.FormSelect>
}