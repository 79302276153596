import Template from "../../template"
import BannerTitleComponent from "../../components/BannerTitle"
import BannerTitle from "../../assets/img/banner/quests-icon.png"

export default function Quests() {
    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Em breve"
                subTitle="Em breve você terá acesso a essa página!"
                imgAlt="quests-icon"
            />


            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Essa página ainda não está disponível para você, mas em breve estará!</p>
                </div>
            </div>
       </Template>
    )
}