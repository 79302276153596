import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../hooks/useFetch";
import api from "../../services/api";
import Template from "../../template"
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import PageLoaderComponent from "../../components/PageLoader";
import InputText from '../../components/Forms/InputText';
import InputSelect from '../../components/Forms/InputSelect'
import RoleSelectorComponent from "../../components/RoleSelectorComponent";
import RegionSelectorComponent from "../../components/RegionSelectorComponent";
import CargoComponent from '../../components/CargoComponent';
import { MaskDocument, MaskPhone, MaskDate, MaskRG } from "../../utils/mask";
import { useModal } from '../../contexts/ModalContext'
import * as S from './style';

// Component
import BannerTitleComponent from "../../components/BannerTitle"
import { useParams } from "react-router-dom";
import { useCampaign } from "../../contexts/CampaignContext";

const handleCreateContract = async (params) => {
  await api.post('/contracts/d4sign', params)
}

const regionais = [
  { name: 'SUL', posicoes: [
    '1º',
    '2º ao 10º',
    '11º ao 30º',
    '31º ao 80º',
  ] },
  { name: 'SP', posicoes: [
    '1º',
    '2º ao 10º',
    '11º ao 30º',
    '31º ao 80º',
  ] },
  { name: 'CO | TM | NORTE', posicoes: [
    '1º',
    '2º ao 5º',
    '6º ao 15º',
    '16º ao 35º',
  ] },
  { name: 'NE', posicoes: [
    '1º',
    '2º ao 5º',
    '6º ao 15º',
    '16º ao 35º',
  ] },
  { name: 'RJ | ES | MG', posicoes: [
    '1º',
    '2º ao 5º',
    '6º ao 15º',
    '16º ao 35º',
  ] }
]

function ModalUpdateContract ({contract, closeModal, handleConfirmCreateContract}) {
  const [params, setParams] = useState(contract)
  const posReg = useCallback(regionName => {
    return regionais.find(r => r.name === regionName).posicoes
  }, [])
  return <>
  <div className="form-row split" style={{marginTop: '1rem'}}>
    <div className="form-item">
      <InputText name="name" label="Nome Completo" id="name" value={params.nomeCompleto} onChange={(value) => setParams({ ...params, nomeCompleto: value })} />
    </div>

    <div className="form-item">
      <InputText name="email" label="Email" id="email" value={params.email} onChange={(value) => setParams({ ...params, email: value })} />
    </div>
  </div>
  <div className="form-row split" style={{marginTop: '1rem'}}>
    <div className="form-item">
      <InputSelect name="etapa" label="Onda" id="etapa" value={params.etapa} onChange={(value) => setParams({ ...params, etapa: value })}>
        <option value="">Selecione uma onda</option>
        <option value="1">Onda 1</option>
        <option value="2">Onda 2</option>
      </InputSelect>
    </div>

    <div className="form-item">
      <InputText name="endereco" label="Endereço completo" id="endereco" value={params.endereco} onChange={(value) => setParams({ ...params, endereco: value })} />
    </div>
  </div>
  <div className="form-row split" style={{marginTop: '1rem'}}>

    <div className="form-item">
      <InputText name="rep_legal" label="Nome rep legal" id="rep_legal" value={params.nomeRepLegal} onChange={(value) => setParams({ ...params, nomeRepLegal: value })} />
    </div>

    <div className="form-item">
      <InputText name="telefone" label="Telefone" id="telefone" value={params.telefone} onChange={(value) => setParams({ ...params, telefone: value })} />
    </div>
  </div>
  <div className="form-row split" style={{marginTop: '1rem'}}>

    <div className="form-item">
      <InputText name="rg" label="RG" id="rg" value={params.rg} onChange={(value) => setParams({ ...params, rg: value })} mask={MaskRG} />
    </div>

    <div className="form-item">
      <InputText name="orgaoEmissor" label="Órgão emissor" id="orgaoEmissor" value={params.orgaoEmissor} onChange={(value) => setParams({ ...params, orgaoEmissor: value })} />
    </div>

    <div className="form-item">
      <InputText name="dataEmissao" label="Data de emissão" id="dataEmissao" value={params.dataEmissao} onChange={(value) => setParams({ ...params, dataEmissao: value })} mask={MaskDate} />
    </div>
  </div>
  <div className="form-row split" style={{marginTop: '1rem'}}>

    <div className="form-item">
      <InputText name="cpf" label="CPF rep legal" id="cpf" value={params.cpf} onChange={(value) => setParams({ ...params, cpf: value })} />
    </div>

    <div className="form-item">
      <InputText name="localData" label="Data e local" id="localData" value={params.localData} onChange={(value) => setParams({ ...params, localData: value })} />
    </div>
  </div>
  <div className="form-row split" style={{marginTop: '1rem'}}>

    <div className="form-item">
      <InputSelect name="" id="" onChange={(val) => setParams({...params, regional: val})}>
        <option value="">Selecione uma regional</option>
        {regionais.map(r => <option value={r.name}>{r.name}</option>)}
      </InputSelect>
    </div>
  </div>
  <div className="form-row split" style={{marginTop: '1rem'}}>

    <div className="form-item">
      Selecione a posição
      {params.regional && posReg(params.regional).map((s, index) => <label htmlFor="opt-pos">
        <input type="radio" name="opt-pos" id="" value={index} onChange={val => setParams({...params, posicao: val.target.value })} />
        {s}
      </label>)}
    </div>
  </div>
  <S.ButtonContainer>
    <button type="button" className="button primary" onClick={() => handleConfirmCreateContract(params)}>Enviar</button>
    <button type="button" className="button danger" onClick={() => closeModal()}>Cancelar</button>
  </S.ButtonContainer>
</>
}

export default function EditUser () {
  const [user, setUser] = useState({});
  const [role, setRole] = useState({});
  const [holding, setHolding] = useState({});
  const [address, setAddress] = useState({});
  const [params, setParams] = useState();
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true,
  });

  const { userId } = useParams();
  const { campaignData } = useCampaign()
  const { openModal, closeModal } = useModal()

  const { data } = useFetch(`/user/${userId}`);

  const loadParams = useCallback (async () => {
    const response = await api.get(`/contracts/${userId}`)
    console.log(response.data.content)
    setParams(response.data.content)
  }, [userId])

  useEffect(() => {
    loadParams()
  }, [loadParams])

  const handleConfirmCreateContract = useCallback(async (params) => {
    openModal({
      header: '',
      body: <>
        <p>Você tem certeza que deseja enviar um novo contrato para o cliente</p>
        <S.ButtonContainer>
          <button type="button" className="button primary" onClick={() => { handleCreateContract(params); closeModal(); toast.success('Contrato criado com sucesso!', { theme: 'colored' });}}>Enviar</button>
          <button type="button" className="button danger" onClick={() => closeModal()}>Cancelar</button>
        </S.ButtonContainer>
      </>
    })
  }, [closeModal, openModal])

  const handleModalCreateContract = useCallback(async () => {
    openModal({
      header: '',
      size: 'large',
      body: <ModalUpdateContract contract={params} closeModal={closeModal} handleConfirmCreateContract={handleConfirmCreateContract} />
    })
  }, [closeModal, handleConfirmCreateContract, openModal, params])

  const handleUserSave = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true,
    })
    try {
      await api.put(`/user/${user.id}`, {...user, address});
      toast.success('Os dados foram salvos', { theme: "colored" });
    } catch (e) {}
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [user, address]);

  const handleApprove = useCallback(async (evt) => {
    evt.preventDefault()
    try {
      await api.patch(`/user/${user.id}/approve`)
      toast.success('Usuário aprovado com sucesso!', { theme: 'colored' })
    } catch (err) {
      toast.error('Ocorreu um erro e não foi possivel aprovar o usuário', { theme: 'colored' })
    }
  }, [user])

  useEffect(() => {
    if(!data) return
    setUser(data)
    setRole(data.role)
    setHolding(data.holding)
    setAddress(data.address || {})
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [campaignData, data])

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Meu perfil"
        subTitle="Info de perfil, mensagens, configuração e muito mais!"
        imgAlt="accounthub-icon"
      />

      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Meu perfil</p>

              <h2 className="section-title">Informação de perfil</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre seu perfil</p>

              <div className="widget-box-content">

                <form className="form" action="" method="POST" onSubmit={handleUserSave}>
                  <div className="form-row split">
                    <div className="form-item">
                      <RoleSelectorComponent value={user.role?.id} onChange={(role) => {setUser({...user, roleId: role.id}); setRole(role)}} />
                    </div>
                  </div>
                  
                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome Completo" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CPF" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskDocument(value) })}  maxLength="14" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <CargoComponent name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                  </div>
                  {role.name === 'Ponto Focal' && <>
                    <div className="form-row split">
                      <p className="widget-box-title">Sobre a holding</p>
                    </div>
                    <div className="form-row split">
                      <div className="form-item">
                        <InputText name="name" label="Nome da holding" id="name" value={holding?.name} onChange={(name) => setHolding({ ...holding, name })} />
                      </div>
                      <div className="form-item">
                        <InputText name="cnpj" label="CNPJ" id="cnpj" value={holding?.cnpj} onChange={(cnpj) => setHolding({ ...holding, cnpj })} />
                      </div>
                      <div className="form-item">
                        <RegionSelectorComponent name="region" label="Regional" id="region" value={holding?.region_id} onChange={(region) => setHolding({ ...holding, region })} />
                      </div>
                    </div>
                  </>}

                  <div className="form-row split">
                    {!user.approved && <div className="form-item">
                      <button className="button primary add-field-button" onClick={handleApprove}>Aprovar usuário</button>
                    </div>}
                    {<div className="form-item">
                      <button type="button" className="button primary add-field-button" onClick={handleModalCreateContract}>Criar contrato</button>
                    </div>}
                    <div className="form-item">
                      <S.SubmitButton className="button primary add-field-button" type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Template >
  )
}