import styled from 'styled-components'

export const Footer = styled.footer`
  text-align: center;
  background: linear-gradient(-45deg, var(--secondary), var(--primary));
  padding: 1.5rem;
  width: 100%;

  p {
    color: var(--white);
    line-height: 1.25rem;
  }
`