import { useCallback, useMemo, useState } from "react"
import axios from "axios"
import { useCampaign } from "../../contexts/CampaignContext"
import { useMessages } from "../../contexts/useMessages"
import HexagonComponent from "../Hexagon"
import Messages from "./Messages"
import ContactCard from "./ContactCard"
import { MaskPhone } from "../../utils/mask"
import * as S from './styles'

function ChatWidget() {
  const { campaignData } = useCampaign();
  const { contacts, activeContact, addMessage } = useMessages();
  const [message, setMessage] = useState('')
  const [filter, setFilter] = useState('')
  const [format, setFormat] = useState()
  const handleChangeTemplate = useCallback((evt) => {
    setFormat(evt.target.value)
  }, [])
  const selectedContacts = useMemo(() => {
    return contacts.filter(a => {
      const lowerCaseName = a.name.toLowerCase()
      const lowerCaseFilter = filter.toLowerCase()
      return lowerCaseName.startsWith(lowerCaseFilter) || lowerCaseName.endsWith(lowerCaseFilter)
    })
  }, [contacts, filter])
  const handleSendMessage = useCallback(async (evt) => {
      evt.preventDefault()
      const params = {
        to: activeContact.phone
      }
      if (!activeContact) return
      if (!format) {
        params.message = message
      } else {
        params.templateId = format
      }
      const response = await axios.post(`${process.env.REACT_APP_WPP_URL}send-message`, params)
      addMessage(response.data)
      setMessage('')
  }, [activeContact, addMessage, format, message])
  return (
    <div className="chat-widget-wrap">
      <div className="chat-widget static">
        <div className="chat-widget-messages" data-simplebar="init">
          <div className="simplebar-wrapper">
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"></div>
            </div>
            <div className="simplebar-mask">
              <div className="simplebar-offset">
                <div className="simplebar-content-wrapper">
                  <div className="simplebar-content">
                    {selectedContacts?.length > 0 ? selectedContacts.map((contact) => (
                      <ContactCard key={contact?.id} contact={contact} />
                    )) : <div>Não há contatos</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="simplebar-placeholder"></div>
          </div>
          <div className="simplebar-track simplebar-horizontal">
            <div className="simplebar-scrollbar"></div>
          </div>
          <div className="simplebar-track simplebar-vertical">
            <div className="simplebar-scrollbar"></div>
          </div>
        </div>
        <form className="chat-widget-form">
          <div className="interactive-input small">
            <input type="text" id="chat-widget-search-2" name="chat_widget_search_2" placeholder="Procurar..." onChange={(evt) => setFilter(evt.target.value)} />
            <div className="interactive-input-icon-wrap">
              <svg className="interactive-input-icon icon-magnifying-glass">
                <use href="#svg-magnifying-glass"></use>
              </svg>
            </div>
          </div>
        </form>
      </div>
      <div className="chat-widget">
        <div className="chat-widget-header">
          <div className="user-status">
            <div className="user-status-avatar">
              <div className="user-avatar small no-outline">
                <HexagonComponent src={(activeContact?.photo && activeContact?.photo !== "null")  ? `${process.env.REACT_APP_IMG_URL}${activeContact.photo}` : campaignData?.defaultAvatar} />
              </div>
            </div>
            <p className="user-status-title"><span className="bold">{activeContact?.name ?? activeContact?.phone ?? 'Não há contato selecionado'}</span></p>
            <p className="user-status-tag">{activeContact?.phone ? MaskPhone(activeContact?.phone.substring(2)) : 'Selecione um contato para ver a conversa'}</p>
          </div>
        </div>
        <Messages />
        <form className="chat-widget-form" onSubmit={handleSendMessage}>
          <div className="form-row split">
            <div className="form-item">
              <S.Select onChange={handleChangeTemplate}>
                <option value="">Texto</option>
                <optgroup label="Templates">
                  <option value="cbf85d7f-a6d2-4917-8895-5824a6163a76">Olá, você entrou em contato com Vitamine suas vendas! No que posso ajudar?</option>
                </optgroup>
              </S.Select>
            </div>
            </div>
            <div className="form-row split">
            <div className="form-item">
              <div className="interactive-input small">
                <input type="text" id="chat-widget-message-text-2"
                  name="chat_widget_message_text_2"
                  value={message}
                  onChange={(v) => setMessage(v.target.value)}
                  placeholder="Escreva a sua mensagem..."
                />
              </div>
            </div>
            <div className="form-item auto-width">
              <button type="submit" className="button primary padded">
                <svg className="button-icon no-space icon-send-message">
                  <use href="#svg-send-message"></use>
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ChatWidget