import DefaultAvatar from '../../assets/img/avatar.png';
import bannerHome1 from '../../assets/img/banner/bannerhome1.png';
import bannerHome2 from '../../assets/img/banner/bannerhome2.png';
import campaignLogo from '../../assets/img/logo-e-mail.png';
import campaignImgBackground from '../../assets/img/bg-onda.png';
import regulamentoMerchan from "../../assets/documents/2022.02.17 Gigantes da Execução Merchan.pdf";
import regulamentoVendas from "../../assets/documents/2022.02.17 - Gigantes da Execução Vendas.pdf";

const CAMPAIGN_TITLE = 'Vitamine suas vendas';
const CAMPAIGN_COMPANY_NAME = 'Mondelez';
const CAMPAIGN_VIDEO_URL = 'https://player.vimeo.com/video/691544711?h=1ed27da7b2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
const CAMPAIGN_FAQ_EMAIL = 'contato@vitaminesuasvendascomtang.com.br';
const CAMPAIGN_COMPANY_PRIVACY_LINK = "https://www.mondelezinternational.com/-/media/Mondelez/Country/Brazil/Aviso-de-privacidade.pdf";


export const staticCampaignData = {
  // titulo da campanha
  title: CAMPAIGN_TITLE,
  // url de vídeo em home
  logo: campaignLogo,
  // url de background da campanha
  imgBackground: campaignImgBackground,
  // url de vídeo em home
  campaignVideo: CAMPAIGN_VIDEO_URL,
  // email de contato fale conosco
  email: CAMPAIGN_FAQ_EMAIL,
  // nome da companhia para tela de ativação
  companyName: CAMPAIGN_COMPANY_NAME,
  // url da politica de privacidade
  privacyLink: CAMPAIGN_COMPANY_PRIVACY_LINK,
  // url
  defaultAvatar: DefaultAvatar,
  // array de urls
  homeBanners: [bannerHome1, bannerHome2],
  // array de objetos de regulamento 
  regulamentos: [{
    equipe: 'MERCHAN',
    // url
    url: regulamentoMerchan,
    date: '2022-02-17'
  }, {
    // equipe GERAL é default para outras equipes
    equipe: 'GERAL',
    url: regulamentoVendas,
    date: '2022-02-17'
  }]
}
