import CPFValidatorAdapter from '../helper/CPFValidatorAdapter'
import CNPJValidatorAdapter from '../helper/CNPJValidatorAdapter'
import PhoneValidatorAdapter from '../helper/PhoneValidatorAdapter'
import EmailValidatorAdapter from '../helper/EmailValidatorAdapter'

const validators = {
  CPFValidatorAdapter: new CPFValidatorAdapter(),
  CNPJValidatorAdapter: new CNPJValidatorAdapter(),
  PhoneValidatorAdapter: new PhoneValidatorAdapter(),
  EmailValidatorAdapter: new EmailValidatorAdapter()
}
export class FormValidator {
  errors = {}
  schema = {}

  constructor (schema) {
    this.schema = schema
  }

  findName (field) {
    return this.schema.properties.find(prop => prop.name === field).displayName
  }

  validateSingleField (form) {
    return (hasError, prop) => {
      if(prop.required) {
        if (!form[prop.name]) {
          this.errors[prop.name] = `O campo ${prop.displayName} é obrigatório`
          return true
        }
      }
  
      if(prop.minLength) {
        if (form[prop.name].length < prop.minLength) {
          this.errors[prop.name] = `O campo ${prop.displayName} deve ter no mínimo ${prop.minLength} caracteres`
          return true
        }
      }
  
      if(prop.maxLength) {
        if (form[prop.name].length > prop.maxLength) {
          this.errors[prop.name] = `O campo ${prop.displayName} deve ter no máximo ${prop.minLength} caracteres`
          return true
        }
      }

      if (prop.const) {
        switch (typeof prop.const)
        {
          case 'object':
            if (prop.const) {
              if (form[prop.name] !== form[prop.const.data]) {
                this.errors[prop.name] = `O campo ${prop.displayName} deve ser igual ao campo ${this.findName(prop.const.data)}`
                return true
              }
            }
            break;
          default:
            if (form[prop.name] !== prop.const) {
              this.errors[prop.name] = `O campo ${prop.displayName} deve ter no mínimo ${prop.minLength} caracteres`
              return true
            }
              break;
        }
      }

      if (prop.validator) {
        if (!validators[prop.validator].isValid(form[prop.name])) {
          this.errors[prop.name] = `O valor do campo ${prop.displayName} é inválido`
          return true;
        }
      }

      return hasError
    }
  }

  validate (form) {
    this.errors = []
    return !this.schema.properties.reduce(this.validateSingleField(form), false)
  }
}