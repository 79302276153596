import { useCallback, useEffect, useState } from 'react' 
import { toast } from 'react-toastify';
import Template from '../../template';
import InputSelect from '../../components/Forms/InputSelect';
import InputText from '../../components/Forms/InputText';
import PageLoader from '../../components/PageLoader';
import BannerTitleComponent from '../../components/BannerTitle';
import Icon from '../../assets/img/banner/overview-icon.png';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import * as S from './style'

function UpdateMeta({value, holding, id, onChange, providerId, stepId}) {
  const [text, setText] = useState()

  const updateMeta = useCallback(async () => {
    try {
      await api.put(`/metas/${id}`, { providerId, stepId, value: text })
      onChange(holding, id, text)
      toast.success('Meta atualizada com sucesso', { theme: 'colored' })
    } catch (err) {
      toast.error('Ocorreu um erro no servidor, por favor tente novamente mais tarde', { theme: 'colored' })
    }
  }, [holding, id, onChange, providerId, stepId, text])

  useEffect(() => {
    setText(value)
  }, [value])

  return <S.InputWrapper>
    <InputText label="Meta" value={text} onChange={setText} />
    <button type="button" className='button primary' onClick={() => updateMeta(id, text)}>
      Salvar
    </button>
  </S.InputWrapper>
}

export default function Metas () {
  const [stepId, setStepId] = useState(1)
  const [metas, setMetas] = useState()
  const [activeHolding, setActiveHolding] = useState()

  const { userCan } = useAuth()

  const loadMetas = useCallback(async () => {
    const response = await api.get(`/metas`, { params: { stepId } })
    setMetas(response.data.metas)
  }, [stepId])

  const updateValue = useCallback((holding, id, value) => {
    const index = metas[holding].values.findIndex(v => v.id === id)
    metas[holding].values[index].value = value
    metas[holding].sum = metas[holding].values.reduce((acc, v) => acc + Number.parseInt(v.value), 0)
    setMetas({...metas});
  }, [metas])

  const handleExport = useCallback(async () => {
    const response = await api.get(`/metas/export`, {
      params: { stepId },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
      }
    })
    const href = URL.createObjectURL(response.data)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', 'mdlz-metas.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }, [stepId])

  useEffect (() => {
    loadMetas()
  }, [loadMetas])
  
  if (!metas) return <PageLoader />
  return <Template>
    
    <BannerTitleComponent
      imgUrl={Icon}
      title="Resultados"
      subTitle=""
      imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <h2 className="section-title">Metas</h2>
      </div>
    </div>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo>
                <InputSelect label="Selecione uma etapa" onChange={(evt) => setStepId(evt)} large={true}>
                  <option value="1">Etapa 1</option>
                  <option value="2">Etapa 2</option>
                </InputSelect>

                {userCan('metas-all:export') && <button className="primary button" onClick={handleExport}>Exportar</button>}
                
              </S.FiltrosCargo>
            </>

            <S.TabelaMetas>
              {Object.keys(metas).map((holding) => <>
                <tr onClick={(evt) => {evt.preventDefault(); setActiveHolding(holding)}} className={'acord ' + (activeHolding === holding && 'active')}>
                  <th colspan="2">{metas[holding].name} ({metas[holding].cnpj})</th>
                  <td>Total de pontos: {metas[holding].sum}</td>
                </tr>
                <tbody>
                  {metas[holding].values.map(v => <tr>
                    <th className="provider-header" colspan="2">{v.name} ({v.cnpj})</th>
                    <td className="provider-header">
                      {userCan('metas-all:update') ?
                        <UpdateMeta {...v} holding={holding} onChange={updateValue} stepId={stepId} /> :
                        <>{v.value}</>
                      }
                    </td>
                  </tr>)}
                </tbody>
              </>)}
            </S.TabelaMetas>
          </div>

        </div>
      </div>
    </div>
  </Template>
}
